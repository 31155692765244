import { useState } from 'react';
import InputDefault from '../../../components/forms/input_default';
import LayoutModal from '../../../components/layouts/modal';
import BtnDefault from '../../../components/buttons/default_button';
import CustomSpinner from '../../../components/customs/spinner';
import { useEffect } from 'react';
import { getGender } from './../../../services/gender_service';
import SelectDefault from '../../../components/forms/select_default';
import { updateProfile } from '../../../services/profile_service';
const UpdateProfile = ({ 
    accessToken, open, setOpen, getName, getPhoneNumber, getGenderId, setRefresh, setAlert, setAlertMessage, setAlertType
}) => {
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [genderId, setGenderId] = useState("")

    const [errorName, setErrorName] = useState(null)
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null)
    const [errorGenderId, setErrorGenderId] = useState(null)

    const [dataGender, setDataGender] = useState([])
    const fetchGender = async () => {
        try {
            const response = await getGender()
            if (response.status === 200) {
                setDataGender(response.data.data)
            }
        } catch (error) {
            console("__")
        }
    }

    useEffect(() => {
        if (getName) {
            setName(getName)
        }

        if (getGenderId) {
            setGenderId(getGenderId)
        }

        if (getPhoneNumber) {
            setPhoneNumber(getPhoneNumber)
        }

        fetchGender()

    }, [getName, getGenderId, getPhoneNumber])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorName(null)
            setErrorPhoneNumber(null)
            setErrorGenderId(null)

            const data = new FormData()
            data.append('name', name)
            data.append('phone_number', phoneNumber)
            data.append('gender_id', genderId)

            const response = await updateProfile(accessToken, data)
            if (response.status === 200) {
                setAlert(true)
                setAlertMessage(response.data.message)
                setAlertType("success")
                setRefresh(true)
                setOpen(false)
            } else {
                setAlert(true)
                setAlertMessage(response.data.message)
                setAlertType("danger")
                setOpen(false)
            }
        } catch (error) {
            setAlert(true)
            setAlertMessage("Silahkan cek koneksi internet Anda..!")
            setAlertType("danger")
            setOpen(false)
        }
        setLoading(false)
    }

    return (
        <LayoutModal name="Update Profil" showModal={open} width="w-[90%] md:w-[700px]" height="h-[380px]">
            <form onSubmit={handleSubmit} className='px-3 flex flex-col gap-3'>
                <InputDefault id="name" label="Nama Lembaga" value={name} setValue={setName} errorMessage={errorName} />
                <InputDefault id="phoneNumber" label="no telepon" value={phoneNumber} setValue={setPhoneNumber} errorMessage={errorPhoneNumber} />
                <SelectDefault
                    name="Jenis Kelamin"
                    options={dataGender
                        .filter(item => item?.name !== "keduanya") // Memfilter untuk mengecualikan "keduanya"
                        .map(item => ({ value: item?.id, label: item?.name }))}
                    value={genderId} onSelect={(e) => setGenderId(e)}
                    getSelected={genderId}
                    width="w-full"
                />

                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>
                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UpdateProfile