import apiService from "./api_service";

export const getAdmin = async ( accessToken, keyword, page, category ) => {
    try {
        const response = await apiService.get(`admin?page=${page}&keyword=${keyword}&category=${category}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const postAdmin = async ( accessToken, data ) => {
    try {
        const response = await apiService.post(`admin/create`, data, {
            headers: {
                Authorization: accessToken,
               'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const deleteAdmin = async ( accessToken, adminId ) => {
    try {
        const response = await apiService.post(`admin/delete/${adminId}`, {}, {
            headers: {
                Authorization: accessToken,
               'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};