import Background from "../../assets/images/background-login.svg"
import Logo from '../../assets/images/Logo.svg'
import Icon from "../../assets/images/icon.svg"
import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import CustomAlert from "../../components/customs/alert"
import CustomSpinner from "../../components/customs/spinner"
import { postCreateNewPassword } from "../../services/auth_service"

const CreateNewPassword = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const code = queryParams.get('code');
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    const [errorPassword, setErrorPassword] = useState(null)
    const [errorPasswordConfirmation, setErrorPasswordConfirmation] = useState(null)

    const handleShowPassword = () => {
        if (showPassword === true) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorPassword(null)
            setErrorPasswordConfirmation(null)

            if (password.length < 8) {
                setShowAlert(true)
                setMessage("Password harus lebih dari 8 karakter..!")
                setTypeAlert('danger')
            } else if (password !== passwordConfirmation) {
                setShowAlert(true)
                setMessage("Password tidak sama..!")
                setTypeAlert('danger')
                setErrorPasswordConfirmation('Password tidak sama..!')
            } else {
                const response = await postCreateNewPassword(email, code, password, passwordConfirmation)
                if (response && response.status === 200) {
                    navigate('/login')
                } else {
                    setShowAlert(true)
                    setMessage("Gagal membuat password baru..!")
                    setTypeAlert('danger')
                }
            }
        } catch (error) {
            setShowAlert(true)
            setMessage("Gagal membuat password baru..!")
            setTypeAlert('danger')
        }

        setLoading(false)
    }

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [typeAlert, setTypeAlert] = useState('')

    return (
        <>
            <Helmet>
                <title>Buat Password Baru</title>
            </Helmet>

            <div className="w-full lg:flex text-white bg-cover" style={{ backgroundImage: `url(${Background})` }}>
                <div className="w-full bg-black/30 flex flex-col lg:flex-row px-5 lg:px-0">
                    <div className="w-full lg:w-1/2 h-screen flex items-center justify-center ">
                        <form onSubmit={handleSubmit} className="w-full lg:w-[70%] bg-black/30 backdrop-blur-md rounded-xl p-5">
                            <div className="flex space-x-3 items-center w-full justify-center">
                                <img src={Logo} alt="Logo Gentra Karya" />
                                <p className="text-3xl font-bold">
                                    Buat Password Baru
                                </p>
                            </div>
                            {/* Input */}
                            <div className="my-3 flex flex-col space-y-3">
                                <div className="my-1">
                                    <label htmlFor="password" className="font-semibold">Password Baru</label>
                                    <div className="w-full relative">
                                        <input
                                            type={showPassword === true ? 'text' : 'password'}
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            placeholder="Masukkan password baru"
                                            className="block text-black w-full px-3 py-2 outline-none focus:border-custom-yellow rounded-md border" required />
                                    </div>
                                </div>

                                <div className="my-1">
                                    <label htmlFor="password" className="font-semibold">Konfirmasi Password Baru</label>
                                    <div className="w-full relative">
                                        <input
                                            type={showPassword === true ? 'text' : 'password'}
                                            name="password"
                                            value={passwordConfirmation}
                                            placeholder="Masukkan password baru kembali"
                                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                                            className="block text-black w-full px-3 py-2 outline-none focus:border-custom-yellow rounded-md border" required />
                                    </div>
                                </div>

                                <button type="button" onClick={handleShowPassword} className={`flex items-center space-x-3 hover:text-custom-yellow transition-colors ${showPassword === true ? 'text-custom-yellow' : 'text-white'}`}>
                                    <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                                    <p>Perlihatkan pasword</p>
                                </button>
                            </div>


                            <div className="flex flex-col space-y-3 mt-5">
                                <button type="submit" className="px-5 py-2 bg-custom-yellow/80 hover:bg-custom-yellow rounded-md w-full font-semibold text-black transition-colors flex items-center justify-center space-x-3">
                                    {loading === true ? <CustomSpinner color={"bg-black"} /> : <p>Simpan Perubahan</p>}

                                </button>
                                <Link to="/" className="text-custom-yellow hover:text-yellow-400 text-center">kembali ke halaman utama</Link>
                            </div>
                        </form>
                    </div>
                    <div className="w-1/2 h-screen hidden lg:flex items-center justify-center">
                        <img src={Icon} alt="Icon" />
                    </div>
                </div>
            </div>


            <CustomAlert
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                message={message}
                type={typeAlert}
            />
        </>
    )
}

export default CreateNewPassword