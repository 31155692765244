import Background from "../../assets/images/background-login.svg";
import Logo from "../../assets/images/Logo.svg";
import Icon from "../../assets/images/icon.svg";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { loginService } from "../../services/auth_service";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faEye } from "@fortawesome/free-solid-svg-icons";
import CustomSpinner from "../../components/customs/spinner";
import LayoutAlert from "../../components/layouts/alert";
import CustomAlert from "../../components/customs/alert";
import InputDefault from './../../components/forms/input_default';
import InputPassword from './../../components/forms/input_password';

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorEmail, setErrorEmail] = useState(null)
  const [errorPassword, setErrorPassword] = useState(null)

  const [loading, setLoading] = useState(false);

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
  };

  const handleChangePassword = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
  };

  const [showAlert, setShowAlert] = useState(false)
  const [message, setMessage] = useState("")
  const [type, setType] = useState("")

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setErrorEmail(null)
      setErrorPassword(null)

      if (email.length === 0) {
        setErrorEmail("Email tidak boleh kosong..!")
      } else if (password.length === 0) {
        setErrorPassword("Password tidak boleh kosong..!")
      } else if (password.length < 8) {
        setErrorPassword("Password minimal 8 karakter..!")
      } else {
        const response = await loginService(email, password);

        if (response) {
          if (response.status === 200) {
            localStorage.setItem("access_token-gentrakarya", `Bearer ${response.data.access_token}`);
            localStorage.setItem("registration_category-gentrakarya", response.data.registration_category?.name);
            localStorage.setItem("registration_sub_category-gentrakarya", response.data.registration_sub_category.name ?? null);
            navigate("/dashboard");
          } else if (response.status === 201) {
            navigate("/email-confirmation?email="+email+"&type=REGISTER");
          } else if (response.status === 401) {
            setShowAlert(true)
            setMessage("Email atau Password salah..!");
            setType("warning")
          } else if (response.status === 404) {
            setShowAlert(true)
            setMessage("Akun Anda belum terdaftar, silahkan melakukan pendaftaran terlebih dahulu..!")
            setType("warning")
          } else {
            setOpenAlert(true)
            setMessage("Login gagal..!")
            setType("error")
          }
        }
      }

    } catch (error) {
      // console.log(error.response.status)
      setOpenAlert(true)

    }
    setLoading(false);
  };

  const handleShowPassword = () => {
    if (showPassword === true) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const [openAlert, setOpenAlert] = useState(false)
  const [messageConfirm, setMessageConfirm] = useState("Akun Anda belum terdaftar, silahkan melakukan pendaftaran terlebih dahulu..!")
  const [message2, setMessage2] = useState("Daftar Sekarang..!")
  return (
    <>
      <Helmet>
        <title>Login - Gentra Karya</title>
      </Helmet>

      <form onSubmit={handleSubmit} className="z-50">
        <div
          className="w-full lg:flex text-white bg-cover z-50"
          style={{ backgroundImage: `url(${Background})` }}
        >
          <div className="w-full bg-black/30 flex flex-col lg:flex-row px-5 lg:px-0 z-[10000]">
            <div className="w-full lg:w-1/2 h-screen flex items-center justify-center ">
              <div className="w-full lg:w-[70%] bg-black/30 backdrop-blur-md rounded-xl p-5">
                <div className="flex space-x-3 items-center w-full justify-center">
                  <img src={Logo} alt="Logo Gentra Karya" />
                  <p className="text-3xl font-bold">Masuk</p>
                </div>
                {/* Input */}
                <div className="my-3 flex flex-col space-y-3">
                  <InputDefault
                    id="email"
                    inputMode="email"
                    type="email"
                    placeholder="example@gmail.com"
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    errorMessage={errorEmail}
                  />

                  <InputPassword
                    id="password"
                    type="password"
                    placeholder="Masukkan Password"
                    label="Password"
                    value={password}
                    setValue={setPassword}
                    errorMessage={errorPassword}
                  />
                </div>

                {/* Button */}
                <div className="flex justify-between">
                  <div className="flex items-center space-x-3">
                    <input type="checkbox" name="remember" id="remember" className="checkbox checkbox-primary" />
                    <label htmlFor="remember" className="text-sm">Ingatkan Saya..?</label>
                  </div>
                  <Link to="/forgot-password" className="text-custom-yellow">
                    Lupa Password..?
                  </Link>
                </div>
                <div className="flex flex-col space-y-3 mt-5">
                  <button
                    type="submit"
                    className="w-full h-10 bg-custom-yellow/80 hover:bg-custom-yellow rounded-md font-semibold text-black transition-colors flex items-center justify-center space-x-3"
                  >
                    {loading === true ? (
                      <CustomSpinner />
                    ) : (
                      <p>Masuk</p>
                    )}

                  </button>
                  <p className="text-center">Tidak memiliki akun..?</p>
                  <Link
                    to="/register"
                    className="px-5 py-2 bg-custom-yellow/30 border-2 hover:bg-custom-yellow border-custom-yellow rounded-md w-full text-center font-semibold text-black transition-colors"
                  >
                    Daftar
                  </Link>
                  <Link
                    to="/"
                    className="text-custom-yellow hover:text-yellow-500 text-center"
                  >
                    kembali ke halaman utama
                  </Link>
                </div>
                {/* <Button></Button> */}
              </div>
            </div>
            <div className="w-1/2 h-screen hidden lg:flex items-center justify-center">
              <img src={Icon} alt="Icon" />
            </div>
          </div>
        </div>
      </form>

      <CustomAlert showAlert={showAlert} setShowAlert={setShowAlert} message={message} type={type} />

      {/* <LayoutAlert showModal={openAlert} width="w-[500px]" height="h-[300px]">
        <div className="p-5 flex flex-col items-center justify-center space-y-3 z-50">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-8xl text-custom-yellow" />
          <p className="text-center">{message}</p>
          <p className="text-center font-semibold">{message2}</p>
          <div className="flex items-center space-x-3 text-sm text-white font-semibold">
            <label className="btn btn-success w-32">
              Tidak
            </label>

            <label onClick={() => setOpenAlert(false)} className="btn btn-error w-32">
              Ya
            </label>
          </div>
        </div>
      </LayoutAlert> */}
    </>
  );
};

export default Login;
