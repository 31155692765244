import apiService from "./api_service";

export const getGaleries = async (page) => {
    try {
        const response = await apiService.get(`galeries?page=${page}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        return error.response
    }
};

export const postGalery = async (accessToken, data) => {
    try {
        const response = await apiService.post(`galery/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response
    } catch (error) {
        return error.response
    }
};

export const deleteGalery = async (accessToken, id) => {
    try {
        const response = await apiService.post(`galery/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response
    } catch (error) {
        return error.response
    }
};