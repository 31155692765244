import { useState } from "react"
import BtnDefault from "../../../components/buttons/default_button"
import LayoutModal from "../../../components/layouts/modal"
import CustomSpinner from "../../../components/customs/spinner"
import InputDefault from "../../../components/forms/input_default"
import SelectDefault from "../../../components/forms/select_default"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import InputPassword from "../../../components/forms/input_password"
import { postAdmin } from "../../../services/admin_service"

const AdminCreate = ({ accessToken, open, setOpen, setRefresh }) => {
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("Gentrakarya123!@")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [gender, setGender] = useState("")
    const [category, setCategory] = useState('')

    const [errorName, setErrorName] = useState(null)
    const [errorEmail, setErrorEmail] = useState(null)
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null)
    const [errorPhoneCategoryId, setErrorPhoneCategoryId] = useState(null)
    const [errorPassword, setErrorPassword] = useState(null)

    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorName(null)
            setErrorEmail(null)
            setErrorPassword(null)
            setErrorPhoneNumber(null)

            const data = new FormData()
            if (image) {
                data.append('image', image)
            }
            data.append('name', name)
            data.append('phone_number', phoneNumber)
            data.append('email', email)
            data.append('password', password)
            data.append('category', category)
            data.append('gender', gender)

            const response = await postAdmin(accessToken, data)
            if (response.status === 201) {
                setImage(null)
                setImagePreview(null)
                setName("")
                setPhoneNumber("")
                setEmail("")
                setPassword("Gentrakarya123!@")
                setCategory("")
                setRefresh(true)
                setOpen(false)
            }
        } catch (error) {
            alert("Gagal menyimpan data..!")
        }
        setLoading(false)
    }

    const categoryOption = [
        { 'name': 'admin' },
        { 'name': 'super admin' }
    ]

    const genderOption = [
        { 'name': 'laki-laki' },
        { 'name': 'perempuan' }
    ]

    return (
        <LayoutModal name="tambah admin" showModal={open} addClass={"w-[90%] md:w-[500px] h-[460px]"} >
            <form onSubmit={handleSubmit} className="px-3 flex flex-col gap-3">
                <div className='flex flex-col items-center justify-center gap-3'>
                    <label htmlFor="image" className='border-2 border-dotted border-black rounded-xl px-3 py-2 flex items-center gap-3 cursor-pointer'>
                        <FontAwesomeIcon icon={faUpload} />
                        <p>Upload Image</p>
                    </label>
                    <input
                        id='image'
                        type="file"
                        accept="image/*"
                        className='hidden'
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setImage(file)
                            if (file) {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setImagePreview(reader.result);
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                    {
                        imagePreview && <img src={imagePreview} alt="Preview" className="w-[200px] aspect-square object-cover rounded-xl border shadow-md" />
                    }
                </div>

                <InputDefault id="name" label="Nama Lengkap" value={name} setValue={setName} errorMessage={errorName} placeholder={"Nama lengkap"} />
                <InputDefault id="phoneNumber" label="No Telepon" value={phoneNumber} setValue={setPhoneNumber} errorMessage={errorName} placeholder={"Nama lengkap"} />
                <InputDefault id="email" label="email" type="email" value={email} setValue={setEmail} errorMessage={errorName} placeholder={"email"} />

                <InputPassword
                    id="password" label="password"
                    value={password} setValue={setPassword}
                    errorMessage={errorPassword} placeholder={"Password"}
                />

                <SelectDefault
                    placeholder="Kategori"
                    options={categoryOption?.map(item => ({ value: item.name, label: item.name }))}
                    value={category} onSelect={(e) => setCategory(e)}
                    width="w-full"
                />

                <SelectDefault
                    placeholder="Jenis kelamin"
                    options={genderOption?.map(item => ({ value: item.name, label: item.name }))}
                    value={gender} onSelect={(e) => setGender(e)}
                    width="w-full"
                />

                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>

                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default AdminCreate