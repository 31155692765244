import { useHref } from "react-router-dom"
import SideLink from "../../../links/sidelink"
import CustomAccording from "../../../customs/according"

const SidebarLembaga = () => {
    const currentPath = useHref()
    return (
        <>
            <SideLink name="Dashboard" link="/dashboard" />
            <CustomAccording name="Data Loker" heigth="h-[90px]" activeLink={currentPath.startsWith('/dashboard/loker') || currentPath.startsWith('/dashboard/lamaran/kerja')}>
                <SideLink name="Lowongan Kerja" link="/dashboard/loker" />
                <SideLink name="Data Lamaran" link="/dashboard/lamaran/kerja" />
            </CustomAccording>
            {/* <CustomAccording name="Data Magang" heigth="h-[90px]" activeLink={currentPath.startsWith('/dashboard/magang') || currentPath.startsWith('/dashboard/lamaran/magang')} >
                    <SideLink name="Lowongan Magang" link="/dashboard/magang" />
                    <SideLink name="Data Lamaran" link="/dashboard/lamaran/magang" />
                </CustomAccording> */}
            <SideLink name="Magang" link="/dashboard/magang" />
            <SideLink name="Sertifikasi" link="/dashboard/sertifikasi" />
            <SideLink name="Workshop" link="/dashboard/workshop" />
            <SideLink name="Pelatihan" link="/dashboard/pelatihan" />
            <SideLink name="Pengaturan" link="/dashboard/pengaturan" />
        </>
    )
}

export default SidebarLembaga