import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"

const InputPassword = ({ id, type, placeholder, label, value, setValue, handleChange, errorMessage, disabled, inputMode }) => {
    const [show, setShow] = useState(false)
    return (
        <div className="w-full flex flex-col text-black text-xs md:text-sm lg:text-base">
            <label htmlFor={id} className="font-semibold capitalize">{label}</label>
            <div className="w-full flex items-center relative">
                <input
                    type={show ? "text" : "password"}
                    id={id}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleChange ? handleChange : (e) => setValue(e.target.value)}
                    className="w-full px-3 py-2 rounded-md border-[2px] outline-none border-gray-200 focus:border-custom-yellow text-black text-xs md:text-sm lg:text-base"
                    disabled={disabled}
                    inputMode={inputMode ?? "text"}
                />
                <button type="button" onClick={() => setShow(!show)} className="absolute text-black right-2 hover:text-custom-yellow">
                    {
                        show ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
                    }
                </button>
            </div>
            {errorMessage && <p className="text-sm text-red-500 lowercase">{errorMessage}</p>}
        </div>
    )
}

export default InputPassword