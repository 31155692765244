import apiService from "./api_service";

export const updatePassword = async ( accessToken, data ) => {
    try {
        const response = await apiService.post(`profile/update/password`, data, {
            headers: {
                Authorization: accessToken,
               'Content-Type': 'application/json'
            }
        });
        return response
    } catch (error) {
        return error.response
    }
};
