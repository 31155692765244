const CustomSpinner = ({ color }) => {
    return (
        <div className="spinner-wave">
            {Array.from({ length: 4 }).map(() => (
                <div className={`spinner-wave-dot ${color}`} />
            ))}
        </div>
    )
}

export default CustomSpinner