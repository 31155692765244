import { useState } from "react";
import { useHref, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { showLoker } from "../../services/loker_service";
import { BaseURL } from "../../services/base_url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomSpinner from './../customs/spinner';
import FormatDate from "../format/date";
import FormatTime from './../format/time';
import { getLokerImage } from "../../services/loker_image_service";
import CustomCurrency from "../customs/currency";
import BtnDefault from "../buttons/default_button";

const CardLokerDetail = ({ loker_id, category }) => {
  const navigate = useNavigate();
  const path = useHref();
  const [accessToken, setAccessToken] = useState(null);

  const [imageGalery, setImageGalery] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState("");

  const [checked, setChecked] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");

  const [openModalAddGalery, setOpenModalAddGalery] = useState(false);

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  const addListClass = (htmlContent) => {
    return htmlContent.replace(/<ol>/g, '<ol class="list-decimal pl-10">');
  };

  const [data, setData] = useState(null);
  const fetchData = async () => {
    try {
      const response = await showLoker(loker_id);
      setData(response.data.data);
    } catch (error) {
      console.log("__")
    }
  };

  const [lokerImage, setLokerImage] = useState([])
  const fetchDataImage = async () => {
    try {
      const response = await getLokerImage(loker_id);
      setLokerImage(response.data.data);
    } catch (error) {
      console.log("__")
    }
  };

  useEffect(() => {
    if (path.startsWith("/dashboard")) {
      const newAccessToken = localStorage.getItem("access_token-gentrakarya");
      if (newAccessToken === null || newAccessToken === "") {
        setAccessToken(null);
      } else {
        setAccessToken(`Bearer ${newAccessToken}`);
      }
    }
    fetchData();
    fetchDataImage();
  }, [loker_id, checked]);

  const handleLamaran = () => {
    // const now = new Date();
    // const formattedDateTime = formatDate(now);
    // setCurrentDateTime(formattedDateTime);
    // if (data?.expired_at > currentDateTime) {
    //   setMessageConfirm(
    //     "Lowongan Kerja Sudah kedaluarsa, Silahkan cari lowongan lain..!"
    //   );
    //   setChecked(true);
    // } else {
    //   if (data?.lamaran === data?.kuota_lowongan) {
    //     setMessageConfirm(
    //       "Lowongan Kerja Sudah penuh, Silahkan cari lowongan lain..!"
    //     );
    //     setChecked(true);
    //   } else {
    //     if (accessToken === "" || accessToken === null) {
    //       navigate("/login");
    //     } else {
    //       navigate(`/loker/${loker_id}/pengajuan/lamaran`);
    //     }
    //   }
    // }
  };

  const handleImageChange = (e) => {
    const selectedFiles = e.target.files;
    setImages([...images, ...selectedFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // await postImageGaleryLoker(accessToken, loker_id, images);
      // fetchDataImage();
      // setLoading(false);
      // setOpenModalAddGalery(false);
      setImages([]);
    } catch (error) {
      setLoading(false);
      setOpenModalAddGalery(false);
      setImages([]);
      alert("gagal menyimpan data");
    }
  };

  const handleDeleteImage = async (e, imageId) => {
    //   e.preventDefault();
    //   setLoading(true);
    //   try {
    //     await deleteImageGaleryLoker(accessToken, imageId);
    //     fetchDataImage();
    //     setLoading(false);
    //   } catch (error) {
    //     setLoading(false);
    //     alert("Gagal menghapus..!");
    //   }
  };

  const CustomRow = ({ name, value }) => {
    return (
      <tr className="text-sm lg:text-base">
        <td className="font-semibold capitalize">{name}</td>
        <td className="px-2 py-2">:</td>
        <td>{value}</td>
      </tr>
    )
  }

  return (
    <div className="w-full px-3 md:px-5 lg:px-10 pt-3">
      <p className="text-2xl font-semibold text-center uppercase bg-blue-400 py-2 ">
        {data?.position}
      </p>

      <div className="w-full lg:w-[50%] mx-auto py-5">
        <div className="w-[100%]  aspect-video bg-gray-400 overflow-hidden mx-auto rounded-xl ">
          {data?.profile_lembaga?.image === null ? (
            ""
          ) : (
            <img
              src={BaseURL + "storage/" + data?.lembaga?.image}
              alt="Foto Profile Lembaga - Gentra Karya"
              className="w-full aspect-video object-cover"
            />
          )}
        </div>

        <div className="w-full flex flex-col items-center my-3 text-sm lg:text-base">
          <p className="space-x-2 flex">
            <span className="font-semibold capitalize">Nama Lembaga / perusahaan :</span>
            <span className="uppercase">
              {data?.lembaga?.name}
            </span>
          </p>
          <p className="space-x-2 flex">
            <span className="font-semibold capitalize">kategori Lembaga :</span>
            <span className="uppercase">
              {data?.lembaga?.category?.name ?? "-"}
            </span>
          </p>
          <p className="space-x-2 flex">
            <span className="font-semibold">Dibuat pada :</span>
            <span>
              <FormatTime data={data?.created_at} />, &nbsp;
              <FormatDate data={data?.created_at} />
            </span>
          </p>
          <p className="space-x-2 flex">
            <span className="font-semibold">Berakhir pada :</span>
            <span>
              <FormatDate data={data?.expired_at} />
            </span>
          </p>
          {category === "masyarakat" ? (
            <button
              type="button"
              onClick={handleLamaran}
              className="px-5 py-1 rounded-md bg-yellow-300 font-semibold mt-3"
            >
              Ajukan Lamaran
            </button>
          ) : (
            ""
          )}
          <div className="flex items-center space-x-3 mt-3">
            <p>Kuota Lowongan : </p>
            <p className="text-xl font-semibold">
              {data?.quota}
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center">
        <BtnDefault>
          Lamar Sekarang
        </BtnDefault>
      </div>

      <div className="w-full flex flex-col lg:flex-row lg:space-x-3 space-y-3 lg:space-y-0">
        {/* Left Content */}
        <div className="w-full lg:w-[65%] space-y-3 md:space-y-0">
          <div className="w-full lg:hidden flex justify-center lg:justify-end mb-3 "></div>
          <CustomRow name="Posisi / Jabatan Lowongan" value={data?.position} />
          <CustomRow name="Kategori Pekerjaan"
            value={
              <p className="capitalize">{data?.category?.name}</p>
            } />
          <CustomRow
            name="jenis kelamin yang dibutuhkan"
            value={['laki-laki', 'perempuan'].includes(data?.gender?.name) ? data?.gender?.name : "Laki-laki & Perempuan"}
          />
          <CustomRow name="status perkawinan" value={data?.marital_status?.name} />
          <CustomRow name="wilayah penempatan" value={data?.placement} />
          <CustomRow name="minimal pendidikan" value={data?.level_of_education?.name} />
          <CustomRow name="minimal gaji"
            value={
              data?.minimum_salary > 0 ?
                <CustomCurrency value={data?.minimum_salary} /> :
                "-"
            } />

          <CustomRow name="maksimal gaji" value={
            data?.maximum_salary > 0 ?
              <CustomCurrency value={data?.maximum_salary} /> :
              "-"
          } />

          <CustomRow name="No telepon" value={data?.phone_number} />
          <CustomRow name="email" value={data?.email} />

        </div>

        {/* Right Content */}
        <div className="w-full lg:w-[35%] space-y-3 lg:space-y-0">
          <CustomRow name="provinsi" value={data?.province?.name} />
          <CustomRow name="Kabupaten / kota" value={data?.regency?.name} />
          <CustomRow name="kecamatan" value={data?.district?.name} />
          <CustomRow name="Desa / kelurahan" value={data?.village?.name} />
          {data?.map &&
            <a
              href={data?.map}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 hover:text-blue-600 transition-colors flex items-center space-x-3 py-3 cursor-pointer"
            >
              <FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon>
              <p>Klik Link Map</p>
            </a>
          }
          <div className="text-sm lg:text-base">
            <p className="font-semibold">Detail Alamat :</p>
            <p className="text-justify">{data?.detail_address}</p>
          </div>
        </div>
      </div>

      <div className="w-full mt-5 pb-10 text-sm lg:text-base">
        <p className="border-b-2 mb-3 font-semibold">Deskripsi Pekerjaan :</p>
        <div
          dangerouslySetInnerHTML={{
            __html: addListClass(data?.description ?? ""),
          }}
        />
      </div>






      <div className="w-full mt-5">
        <div className="flex items-center justify-between bg-gray-300 py-2 px-3 mb-3">
          <p className="font-semibold ">Galeri Loker :</p>
          {category === "lembaga" ? (
            <>
              {/* Modal Add Galery */}
              <button
                onClick={() => setOpenModalAddGalery(true)}
                className="px-3 py-2 bg-custom-yellow rounded-md flex items-center font-semibold space-x-3 "
              >
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                <p>Tambah Galery</p>
              </button>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 pb-5">
          {lokerImage?.map((item) => (
            <div className="w-full aspect-video bg-gray-300 rounded-md overflow-hidden relative object-center group">
              {loading === true ? (
                <div className="w-full aspect-video object-center object-contain bg-gray-300 animate-pulse flex items-center justify-center">
                  <CustomSpinner />
                </div>
              ) : (
                <img
                  src={BaseURL + "storage/" + item?.image}
                  alt={item?.image}
                  className="w-full aspect-video object-center object-contain"
                />
              )}
              {category === "lembaga" ? (
                <button
                  type="button"
                  onClick={(e) => handleDeleteImage(e, item?.id)}
                  className="w-full absolute bottom-0 h-0 z-0 group-hover:h-10 flex items-center justify-center font-serif bg-red-500 text-white hover:bg-red-600 transition-all duration-300"
                >
                  Hapus
                </button>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>


      {/* Modal Confirm */}
      <input
        className="modal-state scale-0"
        id="modal-1"
        type="checkbox"
        checked={checked}
      />
      <div className="modal ">
        <label className="modal-overlay" htmlFor="modal-1"></label>
        <div className="modal-content flex flex-col gap-5 bg-white">
          <svg
            // width="40"
            // height="35"
            viewBox="0 0 40 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto w-32 aspect-square object-contain"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.94024 35.0004H35.0602C38.1402 35.0004 40.0602 31.6604 38.5202 29.0004L23.4602 2.98035C21.9202 0.320352 18.0802 0.320352 16.5402 2.98035L1.48024 29.0004C-0.0597576 31.6604 1.86024 35.0004 4.94024 35.0004ZM20.0002 21.0004C18.9002 21.0004 18.0002 20.1004 18.0002 19.0004V15.0004C18.0002 13.9004 18.9002 13.0004 20.0002 13.0004C21.1002 13.0004 22.0002 13.9004 22.0002 15.0004V19.0004C22.0002 20.1004 21.1002 21.0004 20.0002 21.0004ZM22.0002 29.0004H18.0002V25.0004H22.0002V29.0004Z"
              fill="#F98600"
            />
          </svg>
          <span className="text-center text-black">{messageConfirm}</span>
          <div className="flex gap-3 mt-5">
            <button
              onClick={() => setChecked(false)}
              className="btn btn-error btn-block text-white"
            >
              Kembali
            </button>
          </div>
        </div>
      </div>

      {/* Modal add Galery */}
      <input
        className="modal-state scale-0"
        id="modal-1"
        type="checkbox"
        checked={openModalAddGalery}
      />
      <div className="modal">
        <div className="modal-content w-[80%] max-w-[500px] space-y-3">
          <h2 className="text-xl">Tambah Image Galery</h2>
          <form onSubmit={handleSubmit} className="space-y-3">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handleImageChange}
              className="input-file input-file-primary"
            />
            <div className="flex items-center flex-wrap w-full">
              {images.map((image, index) => (
                <img
                  src={URL.createObjectURL(image)}
                  alt={`image-${index}`}
                  className="w-32 aspect-video object-contain bg-slate-300 rounded-md m-1"
                />
              ))}
            </div>

            <div className="flex gap-3">
              <button
                onClick={() => {
                  setOpenModalAddGalery(false);
                  setImages([]);
                }}
                className="btn btn-error btn-block"
              >
                Kembali
              </button>
              {loading === true ? (
                <div className="w-1/2">
                  <CustomSpinner />
                </div>
              ) : (
                <button type="submit" className="btn btn-primary btn-block">
                  <p>Simpan</p>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CardLokerDetail;