import { useState } from 'react';
import LayoutModal from '../../../components/layouts/modal';
import BtnDefault from '../../../components/buttons/default_button';
import CustomSpinner from '../../../components/customs/spinner';
import InputPassword from './../../../components/forms/input_password';
import { updatePassword } from '../../../services/admin_service copy';
const UpdatePassword = ({ accessToken, open, setOpen, setShowAlert, setMessage, setTypeAlert }) => {
    const [loading, setLoading] = useState(false)

    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [errorNewPassword, setErrorNewPassword] = useState(null)
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorNewPassword(null)
            setErrorConfirmPassword(null)

            if (newPassword.length < 8) {
                setErrorNewPassword("Password harus lebih dari 8 karakter..!")
            } else if (newPassword !== confirmPassword) {
                setErrorConfirmPassword("Password tidak sama..!")
            } else {
                const data = new FormData()
                data.append('new_password', newPassword)
                data.append('new_password_confirmation', confirmPassword)

                const response = await updatePassword(accessToken, data)
                if (response.status === 200) {
                    setShowAlert(true)
                    setMessage(response.data.message)
                    setTypeAlert("success")
                    setOpen(false)
                } else {
                    setShowAlert(true)
                    setMessage(response.data.message)
                    setTypeAlert("danger")
                }
            }

        } catch (error) {
            alert('Silahkan cek koneksi internet Anda..!')
        }
        setLoading(false)
    }

    return (
        <LayoutModal name="Update Password" showModal={open}  width="w-[400px]" height="h-[330px]">
            <form onSubmit={handleSubmit} className='px-3 flex flex-col gap-3'>
                
                <InputPassword id="new_password" placeholder="Masukkan password baru" label="Password Baru" value={newPassword} setValue={setNewPassword} errorMessage={errorNewPassword} />
                <InputPassword id="confirm_password" placeholder="Konfirmasi password baru" label="Konfirmasi Password" value={confirmPassword} setValue={setConfirmPassword} errorMessage={errorConfirmPassword} />

                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>
                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UpdatePassword