import apiService from "./api_service"

export const getLokerImage = async (lokerId) => {
    try {
        const response = apiService.get(`loker-image?loker_id=${lokerId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}
