import { faCaretDown, faCaretRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import HandleClickOutside from '../utils/handle_click_outside';

const SelectFilter = ({
    options,
    name,
    value,
    onSelect,
    placeholder = "Select an option...",
    addClass,
    plusOption,
    handleUpdate,
    loading,
    errorMessage,
    addOption,
    widthContent,
    positionContent
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedOption, setSelectedOption] = useState(null);

    const filterRef = useRef(null);

    useEffect(() => {
        if (searchTerm === '') {
            setFilteredOptions(options);
        } else {
            setFilteredOptions(
                options.filter(option =>
                    option.label.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }

        // Menambahkan logika untuk memilih opsi jika value ada di options
        if (value) {
            const foundOption = options.find(option => option.value === value);
            if (foundOption) {
                setSelectedOption(foundOption);
            }
        }
    }, [searchTerm, options, value]);

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSelect(option.value);
        setIsOpen(false);
    };

    return (
        <>
            <div className="w-full flex flex-col justify-center custom-select-container relative text-xs md:text-sm lg:text-base" ref={filterRef}>
                <div className='w-full'>
                <label htmlFor="" className='font-semibold'>{name}</label>
                <div
                    className={`selected-option w-full  bg-white rounded-md px-3 py-2 border-[2px] focus:border-custom-navy cursor-pointer flex items-center justify-between capitalize ${isOpen ? "border-blue-500" : "border-gray-200"} ${addClass}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <p className='w-full line-clamp-1'>{value ? selectedOption ? selectedOption.label : placeholder : placeholder}</p>
                    <FontAwesomeIcon icon={faCaretRight} className={`transition-all ${isOpen ? 'rotate-90' : 'rotate-0'}`} />
                </div>
                </div>
                {errorMessage && <p className='text-sm text-red-500'>{errorMessage}</p>}
                {isOpen && (
                    <div
                        className={`options-dropdown absolute border-2 border-custom-navy rounded-xl max-h-[300px] min-w-full mt-2 bg-white z-[1000] px-3 pb-3 overflow-auto scrollbar-thin scrollbar-thumb-custom-navy scrollbar-track-custom-dark-yellow scrollbar-track-rounded-full scrollbar-thumb-rounded-full border-blue-500 shadow-md  ${widthContent ?? "w-full"} top-12 md:top-14 lg:top-16 ${positionContent}`}
                    >
                        <div className="bg-white pt-3 sticky top-0">
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search..."
                                className='w-full rounded-xl px-3 py-2 border-2 focus:border-custom-navy outline-none '
                            />
                        </div>
                        {addOption}
                        {loading ? "Loading...!" :
                            filteredOptions.length > 0 ? (
                                filteredOptions.map((option) => (
                                    <div
                                        key={option.value}
                                        onClick={() => handleSelect(option)}
                                        className="option-item flex items-center justify-between capitalize border-b-2 w-full text-start hover:bg-slate-200 transition-colors py-2 px-3 cursor-pointer"
                                    >
                                        <p>{option.label}</p>
                                        {handleUpdate && (
                                            <button type='button' onClick={() => handleUpdate(option)} className="text-yellow-500 hover:text-yellow-600 transition-colors">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="no-options" style={{ padding: '8px' }}>
                                    No options found
                                </div>
                            )
                        }
                        <div>
                            {plusOption}
                        </div>
                    </div>
                )}
            </div>
            <HandleClickOutside setOpen={setIsOpen} filterRef={filterRef} />
        </>
    );
};

export default SelectFilter;
