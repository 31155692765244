import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnDefault from "../../../components/buttons/default_button"
import SlideShowCreate from "./create"
import { useEffect, useState } from "react"
import { Carousel } from "flowbite-react"
import { deleteImageSlider, getImageslider } from "../../../services/image_slider_service"
import { BaseURL } from "../../../services/base_url"
import CustomSpinner from "../../../components/customs/spinner"

const SlideShow = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openCreate, setOpenCreate] = useState(false)
    const [data, setData] = useState([])
    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await getImageslider()
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            setRefresh(false)
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-gentrakarya')
        setAccessToken(getAccessToken)
        fetchData()
    }, [])

    const handleDeleteImage = async (imageId) => {
        setLoading(true)
        try {
            const response = await deleteImageSlider(accessToken, imageId)
            console.log(response);
            if (response.status === 200) {
                setRefresh(true)
            }
        } catch (error) {
            alert("Gagal hapus gamber..!")
        }
        setLoading(false)
    }

    return (
        <>
            <Helmet>Data Slide Show - GENTRAKARYA</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Data Slide Show</p>
                </CustomHeader>

                <div className="w-full bg-gray-600 flex items-center justify-center">
                    <div className="w-full h-[250px] md:h-[450px]">
                        <Carousel>
                            {data.map((item) => (
                                <img src={BaseURL + "storage/" + item.image} alt="Image Slider GENTRAKARYA" className="w-full aspect-video object-contain" />
                            ))}
                        </Carousel>
                    </div>
                </div>

                <div className="w-full h-16 flex items-center justify-between px-3 bg-custom-yellow">
                    <p>Daftar Gambar Slide Show</p>
                    <BtnDefault handleClick={() => setOpenCreate(true)}>
                        <p>Tambah Data</p>
                    </BtnDefault>
                </div>

                {loading ?
                    <div className="w-full flex items-center justify-center py-16">
                        <CustomSpinner />
                    </div> :
                    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 px-3 pt-3 pb-5 ">
                        {data.map((item) => (
                            <div className="relative group border shadow-md rounded-xl">
                                <img
                                    src={BaseURL + "storage/" + item.image}
                                    alt="Image Slider GENTRAKARYA"
                                    className="w-full aspect-video object-cover rounded-md" />
                                <button
                                    type="button"
                                    onClick={() => handleDeleteImage(item.id)}
                                    className="absolute bottom-0 bg-red-500 w-full h-0 text-white group-hover:h-12 transition-all duration-300 font-semibold rounded-t-xl"
                                >
                                    Hapus
                                </button>
                            </div>
                        ))}
                    </div>
                }


            </LayoutDashboard>
            {/* Modal */}
            <SlideShowCreate open={openCreate} setOpen={setOpenCreate} accessToken={accessToken} setRefresh={setRefresh} />
        </>
    )
}

export default SlideShow