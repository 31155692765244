const { Link, useHref } = require("react-router-dom")

const NavLink = ({ children, link, addClass }) => {
    const currentPath = useHref()
    return (
        <Link to={link} className={`px-3 py-2 capitalize border-b-2 ${link === currentPath ? "border-black" : "border-transparent"} hover:border-black font-semibold text-center ${addClass}`}>
            {children}
        </Link>
    )
}

export default NavLink