import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../components/layouts/dashboard';
import CustomHeader from '../../components/customs/header';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { getProfile, logoutService } from '../../services/auth_service';
import { useState, useEffect } from 'react';
import { BaseURL } from '../../services/base_url';
import { getDashboardService } from '../../services/dashboard_service';
import { animate, useMotionValue, useTransform, motion } from 'framer-motion';

const Dashboard = () => {
    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useState("");
    const [profile, setProfile] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const fetchData = async (accessToken) => {
        try {
            const response = await getDashboardService(accessToken);
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleLogout = async () => {
        setLoading(true);
        try {
            const response = await logoutService(accessToken);
            if (response.status === 200) {
                localStorage.clear();
                navigate("/");
            } else {
                console.log("Gagal Logout");
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token-gentrakarya");
        if (accessToken && accessToken !== "") {
            setAccessToken(accessToken);
            getProfile(accessToken).then((response) => {
                if (response && response.status === 200) {
                    setProfile(response.data.data);
                }
            });
            fetchData(accessToken);
        } else {
            navigate("/login");
        }
    }, []);



    return (
        <>
            <Helmet>Dashboard</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <div className='flex items-center justify-center'>
                        <p>Dashboard</p>
                    </div>
                    <div className="dropdown">
                        <label className=" flex items-center space-x-3 cursor-pointer" tabIndex="0">
                            <div className='w-10 aspect-square rounded-full overflow-hidden'>
                                {profile?.image !== null ? (
                                    <img
                                        src={BaseURL + "storage/" + profile?.image}
                                        alt="Gentra Karya"
                                        className="w-10 aspect-square bg-white object-cover rounded-full"
                                    />
                                ) : (
                                    <div className="h-10 aspect-square rounded-full bg-white flex items-center justify-center">
                                        {profile?.name?.charAt(0)}
                                    </div>
                                )}
                            </div>
                            <p className='hidden md:flex'>{profile?.name}</p>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </label>
                        <div className="dropdown-menu bg-gray-50">
                            <Link to="/" className="dropdown-item text-sm text-center">
                                Halaman Utama
                            </Link>
                            <button
                                className="block w-full whitespace-nowrap bg-transparent px-3 py-2 text-black hover:bg-red-200 transition-all text-center font-medium text-sm"
                                type="button"
                                onClick={handleLogout}
                                data-te-dropdown-item-ref
                            >
                                {loading ? "Loading..." : "Keluar"}
                            </button>
                        </div>
                    </div>
                </CustomHeader>

                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-3 md:p-5 lg:p-10">
                    {data.map((item, index) => (
                        <div key={index} className="w-full p-3 bg-white rounded-lg shadow-md text-center">
                            <motion.p
                                className="text-2xl"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, scale: 1.2 }}
                                transition={{ duration: 0.5 }}
                            >
                                {item?.value}
                            </motion.p>
                            <p className="font-semibold">{item?.name}</p>
                        </div>
                    ))}
                </div>
            </LayoutDashboard>
        </>
    )
}

export default Dashboard;
