import { Helmet } from "react-helmet"

import Backgound from "../../assets/images/background.svg";
import Icon from "../../assets/images/icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { resendEmail, verifyEmail } from "../../services/verify_email_service";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import CustomSpinner from "../../components/customs/spinner";
import CustomAlert from "../../components/customs/alert";


const EmailConfirmation = () => {
    const navigate = useNavigate()
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const type = queryParams.get('type');

    const [code, setCode] = useState('')

    const [errorMessage, setErrorMessage] = useState(null)

    const handleChangeCode = (e) => {
        const newCode = e.target.value
        setCode(newCode)
    }

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    const [loadingVerifyEmail, setLoadingVerifyEmail] = useState(false)
    const handleVerifyEmail = async () => {
        setLoadingVerifyEmail(true)
        try {
            if (!code) {
                setShowAlert(true)
                setMessage("Kode Verifikasi harus di isi..!")
                setTypeAlert('warning')
            } else if (code.length > 6 || code.length < 6) {
                setShowAlert(true)
                setMessage("Kode harus berjumlah 6")
                setTypeAlert('warning')
            } else {
                const response = await verifyEmail(email, code, type)
                if (response.status === 200) {
                    if (type === 'REGISTER') {
                        navigate('/login')
                    } else {
                        navigate(`/create-new-password?email=${email}&code=${code}`)
                    }
                } else if (response.status === 400) {
                    setShowAlert(true)
                    setMessage(response.data.message ?? "Silahkan cek kembali inputan anda..!")
                    setTypeAlert('danger')
                } else if (response.status === 422) {
                    setShowAlert(true)
                    setMessage(response.data.message)
                    setTypeAlert('danger')
                } else if (response.status === 404) {
                    setShowAlert(true)
                    setMessage(response.data.message)
                    setTypeAlert('warning')
                } else {
                    setShowAlert(true)
                    setMessage("Koneksi Terputus..!")
                    setTypeAlert('danger')
                }
            }
        } catch (error) {
            setShowAlert(true)
            setMessage("Koneksi Terputus..!")
            setTypeAlert('danger')
        }
        setLoadingVerifyEmail(false)
    }

    const [loadingSendEmail, setLoadingSendEmail] = useState(false)
    const handleResenEmail = async () => {
        setLoadingSendEmail(true)
        try {
            const response = await resendEmail(email, type)
            if (response && response.status === 200) {
                setShowAlert(true)
                setMessage("Kode verifikasi telah dikirim ke email anda..!")
                setTypeAlert('success')
            } else {
                setShowAlert(true)
                setMessage("Gagal mengirim kode verifikasi..!")
                setTypeAlert('danger')
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingSendEmail(false)
    }

    useEffect(() => {

    }, [email, type])

    return (
        <>
            <Helmet>
                <title>Konfirmasi Email</title>
            </Helmet>
            <div className="h-screen w-full bg-custom-dark">
                <div
                    id="welcomeSection"
                    className="w-full mx-auto h-[100%] bg-cover text-white"
                    style={{ backgroundImage: `url(${Backgound})` }}
                >
                    <div className="h-full  lg:bg-gradient-to-r lg:from-custom-dark/90 lg:to-custom-blue/75 flex flex-col lg:flex-row items-center justify-center relative px-5 lg:px-0">
                        <div className="w-full lg:w-[65%] md:w-[80%] lg:pl-24 text-center lg:text-start order-2 lg:order-1">
                            <div className="bg-black/30 backdrop-blur-sm flex flex-col items-center justify-center py-10 rounded-3xl px-3">
                                <h1 className="text-4xl lg:text-3xl font-semibold">Verifikasi Akun</h1>
                                <h2 className="text-xs md:text-sm lg:text-base mt-3 lg:mt-0 tracking-wide">
                                    Kode verifikasi telah kami kirim ke email Anda..!
                                </h2>
                                <div className="mt-10 text-center flex flex-col items-center">
                                    <p>Masukan kode verifikasi</p>
                                    <input
                                        value={code}
                                        onChange={handleChangeCode}
                                        type="text"
                                        className="block text-black px-3 py-2 outline-none focus:border-custom-yellow rounded-md borxsder text-5xl font-semibold text-center w-60 tracking-wide"
                                        placeholder="123456" />
                                    {errorMessage === null ? '' : <p className="text-red-500 bg-white rounded-full px-3 py-1">{errorMessage}</p>}
                                </div>
                                <button
                                    onClick={handleVerifyEmail}
                                    type="button"
                                    className="bg-yellow-300 hover:bg-custom-yellow px-5 py-2 rounded-md font-semibold text-black w-32 mt-5 text-sm flex items-center justify-center"
                                >
                                    {loadingVerifyEmail ? <CustomSpinner color="bg-black" /> : 'Kirim'}
                                </button>
                                <div className="flex flex-col md:flex-row items-center justify-start md:space-x-3 mt-10">
                                    <p>Belum menerima kode verifikasi..?</p>
                                    <button type="button" onClick={handleResenEmail} className="text-custom-yellow hover:text-yellow-400 transition-colors font-semibold">
                                        {loadingSendEmail ?
                                            <CustomSpinner color="bg-custom-yellow" /> :
                                            "Kirim ulang kode"
                                        }
                                    </button>
                                </div>

                                <button type="button" onClick={() => navigate(-1)} className="text-custom-yellow font-semibold mt-10">Kembali</button>
                            </div>
                        </div>

                        <div className="hidden  w-full lg:w-1/2 lg:h-full lg:flex items-center justify-center lg:justify-end  lg:pr-24 order-1 lg:order-2">
                            <img
                                src={Icon}
                                alt="Icon"
                                className="h w-[80%] lg:h-[60%] aspect-square object-contain "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <CustomAlert
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                message={message}
                type={typeAlert}
            />
        </>
    )
}

export default EmailConfirmation