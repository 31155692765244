import { Helmet } from "react-helmet"
import LayoutLandingPage from "../../../../components/layouts/landing_page"
import { useEffect, useState } from "react";
import { getLoker } from "../../../../services/loker_service";
import { getDistrict, getProvince, getRegency, getVillage } from "../../../../services/location_service";
import CustomSpinner from "../../../../components/customs/spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import InputSearch from '../../../../components/forms/input_search';
import LoadingLoker from "../../../../components/loadings/loker";
import CardLoker from "../../../../components/cards/loker";
import Footer from "../../sections/footer";
import SelectFilter from "../../../../components/forms/select_filter";

const LokerLp = () => {
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const [keyword, setKeyword] = useState("");

    const fetchData = async (provinceId, regencyId, districtId, villageId, keyword, currentPage) => {
        try {
            setLoading(true);
            const response = await getLoker(null, null, provinceId, regencyId, districtId, villageId, keyword, currentPage);
            if (response.status === 200) {
                setData(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
            }
            setLoading(false);
        } catch (error) {
            console.log("---")
        }
    };


    const [province, setProvince] = useState([]);
    const [provinceId, setProvinceId] = useState(null);
    const fetchDataProvince = async () => {
        try {
            const response = await getProvince()
            if (response.status === 200) {
                setProvince(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [regency, setRegency] = useState([]);
    const [regencyId, setRegencyId] = useState(null);
    const fetchDataRegency = async (provinceId) => {
        try {
            const response = await getRegency(provinceId)
            if (response.status === 200) {
                setRegency(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState(null);
    const fetchDataDistrict = async (regencyId) => {
        try {
            const response = await getDistrict(regencyId)
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [village, setVillage] = useState([]);
    const [villageId, setVillageId] = useState(null);
    const fetchDataVillage = async (districtId) => {
        try {
            const response = await getVillage(districtId)
            if (response.status === 200) {
                setVillage(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        try {
            fetchData(provinceId, regencyId, districtId, villageId, keyword, currentPage)
            fetchDataProvince()
        } catch (error) {
            alert("Silahkan periksa koneksi internet Anda..!");
        }
        // eslint-disable-next-line
    }, []);

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleProvince = (e) => {
        const provinceId = e;
        setProvinceId(provinceId);

        fetchDataRegency(provinceId)
        setRegencyId(null);

        setDistrict([]);
        setDistrictId(null);

        setVillage([]);
        setVillageId(null);

        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleRegency = (e) => {
        const regencyId = e;
        setRegencyId(regencyId);

        fetchDataDistrict(regencyId)
        setDistrictId(null);

        setVillage([]);
        setVillageId(null);

        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleDistrict = (e) => {
        const districtId = e;
        setDistrictId(districtId);

        fetchDataVillage(districtId)
        setVillageId(null);

        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleVillage = (e) => {
        const vilageId = e;
        setVillageId(vilageId);

        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );

    };

    const handleSearch = (e) => {
        setKeyword(e.target.value)
        setCurrentPage(1)
        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            e.target.value,
            1
        );
    };
    return (
        <>
            <Helmet>Lowongan Pekerjaan - GENTRAKARYA</Helmet>
            <LayoutLandingPage>
                <div className="sticky top-16 z-10 h-14 flex items-center justify-between px-10 text-sm border-b-2 bg-blue-300 space-x-3">
                    <div className="flex items-center space-x-3">
                        <FontAwesomeIcon
                            icon={faBell}
                            className="h-8 aspect-square text-black"
                        />
                        <p className="text-base md:text-2xl font-semibold">
                            Informasi Lowongan Pekerjaan - Gantra Karya
                        </p>
                    </div>

                    {loading === true ? (
                        <CustomSpinner />
                    ) : (
                        <div className="flex items-center space-x-2 font-semibold">
                            <p className="text-2xl">
                                <span className="md:hidden">(</span>
                                <span>{totalData}</span>
                                <span className="md:hidden">)</span>
                            </p>
                            <p className="hidden md:block text-base">Total Loker</p>
                        </div>
                    )}
                </div>

                <div className=" grid grid-cols-1 md:grid-cols-3 p-3 sticky top-16 border-b-2 bg-white border-black z-10 gap-3">
                    <div className={`w-full grid grid-cols-1 gap-3`}>
                        <InputSearch value={keyword} handleChange={handleSearch} addClass="w-full" />
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                        <SelectFilter
                            placeholder="Pilih provinsi"
                            options={province.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setProvinceId(null)
                                    setRegency([])
                                    setRegencyId(null)
                                    setDistrict([])
                                    setDistrictId(null)
                                    setVillage([])
                                    setVillageId(null)

                                    fetchData(null, null, null, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                                </button>
                            }
                            value={provinceId} onSelect={(e) => handleProvince(e)}
                            widthContent="w-[300px]"
                        />
                        <SelectFilter
                            placeholder="Pilih kabupaten / kota"
                            options={regency.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setDistrict([])
                                    setDistrictId(null)
                                    setVillage([])
                                    setVillageId(null)

                                    fetchData(provinceId, null, null, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                            </button>
                            }
                            value={regencyId} onSelect={(e) => handleRegency(e)}
                            widthContent="w-[300px]"
                            positionContent="right-0 md:left-0"
                        />
                    </div>

                    <div className="grid grid-cols-2  gap-3">

                        <SelectFilter
                            placeholder="Pilih kecamatan"
                            options={district.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setDistrictId(null)
                                    setVillage([])
                                    setVillageId(null)

                                    fetchData(provinceId, regencyId, null, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                                </button>
                            }
                            value={districtId} onSelect={(e) => handleDistrict(e)}
                            widthContent="w-[300px]"
                            positionContent="left-0 md:right-0"
                        />

                        <SelectFilter
                            placeholder="Pilih desa / kelurahan"
                            options={village.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setVillageId(null)

                                    fetchData(provinceId, regencyId, districtId, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                                </button>
                            }
                            value={villageId} onSelect={(e) => handleVillage(e)}
                            widthContent="w-[300px]"
                            positionContent="right-0"
                        />

                    </div>
                </div>

                <div className="w-full px-5 lg:px-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-5">
                    {loading === true ? (
                        <>
                            {Array.from({ length: 3 }, (_, index) => (
                                <LoadingLoker />
                            ))}
                        </>
                    ) : (
                        <>
                            {data?.map((item) => (
                                <CardLoker
                                    link={`/loker/detail/${item?.id}`}
                                    position={item?.position}
                                    lembagaName={item?.lembaga?.name}
                                    lembagaCategory={item?.lembaga?.category?.name ?? "-"}
                                    minimumSalary={item?.minimum_salary}
                                    maximumSalary={item?.maximum_salary}
                                    quota={item?.quota}
                                    createdAt={item?.created_at}
                                    expiredAt={item?.expired_at}
                                />
                            ))}
                        </>
                    )}
                </div>

                <Footer/>

            </LayoutLandingPage>
        </>
    )
}

export default LokerLp