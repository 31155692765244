import Logo from "../../../assets/images/Logo.svg"
import { useEffect, useState } from "react"
import SidebarMasyarakat from "./menus/masyarakat"
import SidebarLembaga from "./menus/lembaga"
import SidebarSuperAdmin from "./menus/super_admin"
import SidebarAdmin from "./menus/admin"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import HandleClickOutside from "../../utils/handle_click_outside"
import { useRef } from "react"

const Sidebar = () => {
    const [registrationCategory, setRegistrationCategory] = useState(null)
    const [sidebarMenu, setSidebarMenu] = useState(null)
    useEffect(() => {
        const getRegistrationCategory = localStorage.getItem('registration_category-gentrakarya')
        setRegistrationCategory(getRegistrationCategory)
        switch (getRegistrationCategory) {
            case "masyarakat":
                setSidebarMenu(<SidebarMasyarakat />)
                break;
            case "lembaga":
                setSidebarMenu(<SidebarLembaga />)
                break;
            case "super admin":
                setSidebarMenu(<SidebarSuperAdmin />)
                break;
            case "admin":
                setSidebarMenu(<SidebarAdmin />)
                break;
            default:
                setSidebarMenu(<SidebarMasyarakat />)
                break;
        }
    }, [])

    const [openMenu, setOpenMenu] = useState(false)
    const ref = useRef(null)

    return (
        <>
        {/* Desktop Menu */}
        <aside className="w-64 h-screen bg-blue-500 space-y-3 text-sm hidden lg:block overflow-auto scrollbar-none ">
            <div className="w-full sticky top-0  bg-blue-500 z-20">
                <div className="py-5 start-0">
                    <img src={Logo} alt="Logo Gentra Karya" className="w-[40%] aspect-square mx-auto" />
                </div>
                <div className="w-full h-1 rounded-full bg-white"></div>
            </div>
            <div className="w-full space-y-1 pl-3 flex flex-col gap-1 pb-5">
                {sidebarMenu}
            </div>
        </aside>

         {/* Mobile Menu */}
         <div className="fixed bottom-5 right-5 z-50 md:hidden" ref={ref}>
                <button type="button" onClick={() => setOpenMenu(!openMenu)} className="bg-custom-yellow w-16 aspect-square rounded-full absolute bottom-0 right-0 z-10 shadow-md ">
                    <FontAwesomeIcon icon={faHome} />
                </button>
                <div className={` bg-black/30 rounded-xl absolute bottom-5 right-5 p-3 backdrop-blur-sm`}>
                    <div className={`flex flex-col gap-3 overflow-auto scrollbar-none ${openMenu ? 'w-[250px] max-h-[400px]' : 'w-0 max-h-0'} transition-all duration-500`}>
                    {sidebarMenu}
                    </div>
                </div>
            </div>

            <HandleClickOutside setOpen={setOpenMenu} filterRef={ref} />
        </>
    )
}

export default Sidebar