import apiService from "./api_service"

export const getSampleLoker = async () => {
    try {
        const response = apiService.get('loker/sample', {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}

export const getLoker = async (accessToken, category, provinceId, regencyId, districtId, villageId, keyword, page) => {
    try {
        const response = apiService.get(`loker`, {
            params: {
                category: category,
                province_id: provinceId,
                regency_id: regencyId,
                district_id: districtId,
                village_id: villageId,
                keyword: keyword,
                page: page
            },
            headers: {
                Authorization: accessToken ?? null,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
        console.error('Error fetching data:', error);
    }
}

export const showLoker = async (lokerId) => {
    try {
        const response = apiService.get(`loker/show/${encodeURIComponent(lokerId)}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
        console.error('Error fetching data:', error);
    }
}

export const addressLoker = async (accessToken, lokerId) => {
    try {
        const response = apiService.get(`loker/${encodeURIComponent(lokerId)}/address`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
        console.error('Error fetching data:', error);
    }
}

export const PersyaratanLoker = async (accessToken, lokerId) => {
    try {
        const response = apiService.get(`loker/${encodeURIComponent(lokerId)}/persyaratan`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
        console.error('Error fetching data:', error);
    }
}

export const deleteLoker = async (accessToken, lokerId) => {
    try {
        const response = apiService.post(`loker/delete/${encodeURIComponent(lokerId)}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}


export const postLoker = async (accessToken, data ) => {
    try {
        const response = await apiService.post(`loker/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};