import { useEffect, useState } from "react";
import { getSampleLoker } from "../../../services/loker_service";
import { Link, useNavigate } from "react-router-dom";
import CardLoker from "../../../components/cards/loker";
import Title from "../../../components/customs/title";

const SampleLoker = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const fetchSampleLoker = async () => {
    try {
      const response = await getSampleLoker();
      setData(response.data.data);
    } catch (error) {
      console.log("__")
    }
  };

  useEffect(() => {
    fetchSampleLoker();
  }, []);
  return (
    <>
      <div className="w-full py-10 px-5 lg:px-10 ">
        <div className="flex items-center justify-between">
          <Title name="Info Lowongan Pekerjaan"></Title>
          <Link
            to="/loker"
            className="text-black bg-custom-yellow px-3 py-2 rounded-md font-semibold"
          >
            Lihat Semua
          </Link>
        </div>
        <div className="w-full mt-3 h-[2px] rounded-full bg-black"></div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-3">
          {data?.map((item) => (
            <CardLoker
              link={`/loker/detail/${item?.id}`}
              position={item?.position}
              lembagaName={item?.lembaga?.name}
              lembagaCategory={item?.lembaga?.category?.name ?? "-"}
              minimumSalary={item?.minimum_salary}
              maximumSalary={item?.maximum_salary}
              quota={item?.quota}
              createdAt={item?.created_at}
              expiredAt={item?.expired_at}
            />
          ))}
        </div>
      </div>
    </>
  );
};
export default SampleLoker;
