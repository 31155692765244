const LayoutModal = ({ name, children, showModal, zIndex, addClass, width, height }) => {
    return (
        <div className={` fixed top-0 left-0 w-full h-screen flex items-center justify-center transition-all duration-500  ${showModal === true ? zIndex ?? "z-[10000]" : "z-0"}`}>
            <div className={`transition-all duration-500 flex items-center bg-black/50 justify-center ${showModal === true ? 'w-full h-screen' : 'w-0 h-0 delay-300 rounded-full'}`}>
                <div className={`bg-white rounded-3xl transition-all duration-500 relative overflow-auto ${showModal === true ? `${width ?? "w-[90%]"} ${height ?? "h-[90%]"} delay-300 ${addClass}` : ' w-0 h-0 rounded-full'}`}>
                    <div className="w-full flex items-end justify-center p-3 bg-custom-yellow font-bold absolute top-0 z-20 uppercase text-black">
                        {name}
                    </div>
                    <div className="w-full h-full py-16 overflow-auto scrollbar-thin scrollbar-track-custom-blue scrollbar-thumb-custom-yellow text-black">
                        {children}
                    </div>
                    <div className="w-full flex items-end justify-center p-3 uppercase bg-custom-yellow font-bold absolute bottom-0 z-20 text-black">
                        gentrakarya
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LayoutModal