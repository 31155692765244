import LayoutDashboard from './../../../components/layouts/dashboard';
import CustomHeader from './../../../components/customs/header';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { getLoker } from '../../../services/loker_service';
import { getDistrict, getProvince, getRegency, getVillage } from '../../../services/location_service';
import CardLoker from '../../../components/cards/loker';
import LoadingLoker from '../../../components/loadings/loker';
import InputSearch from '../../../components/forms/input_search';
import BtnLink from '../../../components/buttons/link_button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomPaginate from '../../../components/customs/paginate';
import { useNavigate } from 'react-router-dom';
import { getProfileLembaga } from '../../../services/profile_lembaga_service';
import CustomAlert from '../../../components/customs/alert';
import BtnDefault from '../../../components/buttons/default_button';
import SelectDefault from '../../../components/forms/select_default';
import SelectFilter from '../../../components/forms/select_filter';

const Loker = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [registrationCategory, setRegistrationCategory] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);


    const [keyword, setKeyword] = useState("");

    const fetchData = async (accessToken, category, provinceId, regencyId, districtId, villageId, keyword, currentPage) => {
        try {
            setLoading(true);
            const response = await getLoker(accessToken, category, provinceId, regencyId, districtId, villageId, keyword, currentPage);
            if (response.status === 200) {
                setData(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
            }
            setLoading(false);
        } catch (error) {
            console.log("---")
        }
    };


    const [province, setProvince] = useState([]);
    const [provinceId, setProvinceId] = useState(null);
    const fetchDataProvince = async () => {
        try {
            const response = await getProvince()
            if (response.status === 200) {
                setProvince(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [regency, setRegency] = useState([]);
    const [regencyId, setRegencyId] = useState(null);
    const fetchDataRegency = async (provinceId) => {
        try {
            const response = await getRegency(provinceId)
            if (response.status === 200) {
                setRegency(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState(null);
    const fetchDataDistrict = async (regencyId) => {
        try {
            const response = await getDistrict(regencyId)
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [village, setVillage] = useState([]);
    const [villageId, setVillageId] = useState(null);
    const fetchDataVillage = async (districtId) => {
        try {
            const response = await getVillage(districtId)
            if (response.status === 200) {
                setVillage(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [profileLembaga, setProfileLembaga] = useState(null)
    const fetchProfileLembaga = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getProfileLembaga(accessToken)
            if (response.status === 200) {
                setProfileLembaga(response.data.data)
            }
        } catch (error) {
            console.log('__')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, category, provinceId, regencyId, districtId, villageId, keyword, currentPage)
            setRefresh(false)
        }
    }, [refresh]);

    useEffect(() => {
        try {
            const getAccessToken = localStorage.getItem('access_token-gentrakarya')
            const getRegistrationCategory = localStorage.getItem('registration_category-gentrakarya')
            if (getAccessToken) {
                setAccessToken(getAccessToken)
                setRegistrationCategory(getRegistrationCategory)
                fetchData(getAccessToken, category, provinceId, regencyId, districtId, villageId, keyword, currentPage)
                fetchDataProvince()
                fetchProfileLembaga(getAccessToken)
            } else (
                navigate('/login')
            )
        } catch (error) {
            alert("Silahkan periksa koneksi internet Anda..!");
        }
        // eslint-disable-next-line
    }, []);

    const handlePageChange = (currentPage) => {
        setCurrentPage(currentPage);
        fetchData(
            accessToken,
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleProvince = (e) => {
        const provinceId = e;
        setProvinceId(provinceId);
        fetchDataRegency(provinceId)

        setDistrict([]);
        setDistrictId(0);

        setVillage([]);
        setVillageId(0);

        fetchData(
            accessToken,
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleRegency = (e) => {
        const regencyId = e;
        setRegencyId(regencyId);

        fetchDataDistrict(regencyId)

        setVillage([]);
        setVillageId(0);

        fetchData(
            accessToken,
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleDistrict = (e) => {
        const districtId = e;
        setDistrictId(districtId);

        fetchDataVillage(districtId)
        fetchData(
            accessToken,
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleVillage = (e) => {
        const vilageId = e;
        setVillageId(vilageId);

        fetchData(
            accessToken,
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );

    };

    const handleSearch = (e) => {
        setKeyword(e.target.value)
        setCurrentPage(1)
        fetchData(
            accessToken,
            category,
            provinceId,
            regencyId,
            districtId,
            villageId,
            e.target.value,
            1
        );
    };

    const handlePaginate = (newPage) => {
        fetchData(
            accessToken,
            category,
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            newPage
        );
    }

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState("")
    const [typeAlert, setTypeAlert] = useState("success")

    const [category, setCategory] = useState("semua")
    const optionsCategory = [
        { value: "semua" },
        { value: "gentrakarya" }
    ]

    return (
        <>
            <Helmet>Lowongan Pekerjaan - GENTRAKARYA</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Lowongan Pekerjaan <span className="text-xl">( {totalData} <span className="text-base">data</span> )</span></p>
                    <BtnDefault
                        handleClick={() => {
                            if (!['super admin', 'admin'].includes(registrationCategory) && !profileLembaga) {
                                setShowAlert(true);
                                setMessage("Silahkan lengkapi data lembaga anda terlebih dahulu..!");
                                setTypeAlert("warning");
                            } else {
                                navigate('/dashboard/loker/create');
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <p className="hidden md:flex">Loker Baru</p>
                    </BtnDefault>
                </CustomHeader>

                <div className=" grid grid-cols-1 md:grid-cols-3 p-3 sticky top-16 border-b-2 bg-white border-black z-10 gap-3">
                    <div className={`w-full grid grid-cols-1 gap-3 ${['admin', 'super admin'].includes(registrationCategory) && 'md:grid-cols-2'}`}>
                        <InputSearch value={keyword} handleChange={handleSearch} addClass="w-full" />

                        {['admin', 'super admin'].includes(registrationCategory) && (
                            <SelectDefault
                                value={category}
                                options={optionsCategory.map((item) => ({ value: item.value, label: item.value }))}
                                onSelect={(e) => {
                                    setCategory(e)
                                    setCurrentPage(1)
                                    setKeyword("")
                                    fetchData(accessToken, e, provinceId, regencyId, districtId, villageId, "", 1)
                                }}
                                width="w-full"
                            />
                        )}
                    </div>

                    <div className="grid grid-cols-2 gap-3">
                        <SelectFilter
                            placeholder="Pilih provinsi"
                            options={province.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setProvinceId(null)
                                    setRegency([])
                                    setRegencyId(null)
                                    setDistrict([])
                                    setDistrictId(null)
                                    setVillage([])
                                    setVillageId(null)

                                    fetchData(accessToken, category, null, null, null, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                                </button>
                            }
                            value={provinceId} onSelect={(e) => handleProvince(e)}
                            widthContent="w-[300px]"
                        />
                        <SelectFilter
                            placeholder="Pilih kabupaten / kota"
                            options={regency.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setDistrict([])
                                    setDistrictId(null)
                                    setVillage([])
                                    setVillageId(null)

                                    fetchData(accessToken, category, provinceId, null, null, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                            </button>
                            }
                            value={regencyId} onSelect={(e) => handleRegency(e)}
                            widthContent="w-[300px]"
                            positionContent="right-0 md:left-0"
                        />
                    </div>

                    <div className="grid grid-cols-2  gap-3">

                        <SelectFilter
                            placeholder="Pilih kecamatan"
                            options={district.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setDistrictId(null)
                                    setVillage([])
                                    setVillageId(null)

                                    fetchData(accessToken, category, provinceId, regencyId, null, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                                </button>
                            }
                            value={districtId} onSelect={(e) => handleDistrict(e)}
                            widthContent="w-[300px]"
                            positionContent="left-0 md:right-0"
                        />

                        <SelectFilter
                            placeholder="Pilih desa / kelurahan"
                            options={village.map(item => ({ value: item.id, label: item.name }))}
                            addOption={
                                <button onClick={() => {
                                    setVillageId(null)

                                    fetchData(accessToken, category, provinceId, regencyId, districtId, null, keyword, currentPage)
                                }}
                                    className='px-3 py-2 border-b text-center hover:bg-gray-200 w-full'
                                >
                                    Semua
                                </button>
                            }
                            value={villageId} onSelect={(e) => handleVillage(e)}
                            widthContent="w-[300px]"
                            positionContent="right-0"
                        />

                    </div>
                </div>

                <div className="w-full px-3 md:px-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-5">
                    {loading === true ? (
                        <>
                            {Array.from({ length: 8 }, (_, index) => (
                                <LoadingLoker />
                            ))}
                        </>
                    ) : (
                        <>
                            {data?.map((item) => (
                                <CardLoker
                                    link={`/dashboard/loker/detail/${item?.id}`}
                                    position={item?.position}
                                    lembagaName={item?.lembaga?.name}
                                    lembagaCategory={item?.lembaga?.category?.name ?? "-"}
                                    minimumSalary={item?.minimum_salary}
                                    maximumSalary={item?.maximum_salary}
                                    quota={item?.quota}
                                    createdAt={item?.created_at}
                                    expiredAt={item?.expired_at}
                                />
                            ))}
                        </>
                    )}
                </div>

                {lastPage > 1 || loading &&
                    < div className='md:px-3 sticky bottom-0 left-0'>
                        <div className="flex items-center justify-end w-full bg-blue-300 px-3 py-2">
                            <CustomPaginate currentPage={currentPage} lastPage={lastPage} handlePaginate={handlePaginate} />
                        </div>
                    </div>
                }
            </LayoutDashboard >

            <CustomAlert
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                message={message}
                type={typeAlert}
            />
        </>
    )
}

export default Loker