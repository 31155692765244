import { Helmet } from "react-helmet"
import LayoutLandingPage from './../../../components/layouts/landing_page';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { getLayanan } from "../../../services/layanan_service";
import { useState } from "react";

const LayananLp = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [loading, setLoading] = useState(false)

    const [openCreate, setOpenCreate] = useState(false)

    const [data, setData] = useState([])
    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await getLayanan()
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        const getAccessToken = localStorage.getItem("access_token-gentrakarya")
        setAccessToken(getAccessToken)
        fetchData()
    }, [])
    return (
        <>
            <Helmet>Layanan GENTRAKARYA</Helmet>
            <LayoutLandingPage>
                <div className="sticky top-16 z-10 h-14 flex items-center justify-between px-10 text-sm border-b-2 bg-blue-300 space-x-3">
                    <div className="flex items-center space-x-3">
                        <FontAwesomeIcon
                            icon={faBell}
                            className="h-8 aspect-square text-black"
                        />
                        <p className="text-base md:text-2xl font-semibold">
                            Informasi Layanan - Gantra Karya
                        </p>
                    </div>
                </div>


                <div className="accordion-group accordion-group-secondary pt-20 md:pt-0 px-3 md:px-5 lg:px-10">
                    {data?.map((item, index) => (
                        <div className="accordion">
                            <input type="checkbox" id={`toggle-${index}`} className="accordion-toggle" />
                            <label htmlFor={`toggle-${index}`} className="text-sm md:text-base bg-white text-black font-bold py-3 border-b-2 cursor-pointer">
                                <p className="uppercase">{item?.name}</p>
                            </label>
                            <div className="accordion-content text-content2">
                                <div className="min-h-0 pl-10 pr-5 text-sm md:text-base text-black">
                                    <div dangerouslySetInnerHTML={{ __html: item?.detail }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </LayoutLandingPage>
        </>
    )
}

export default LayananLp