import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"

const BtnBack = () => {
    const navigate = useNavigate()
    return (
        <button type="button" onClick={()=>navigate(-1)} className="flex items-center justify-center gap-3 px-3 py-2 font-semibold">
            <FontAwesomeIcon icon={faChevronLeft} />
            <p>Kembali</p>
        </button>
    )
}

export default BtnBack