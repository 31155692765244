import { useHref } from "react-router-dom"
import CustomAccording from "../../../customs/according"
import SideLink from "../../../links/sidelink"

const SidebarAdmin = () => {
    const currentPath = useHref()
    return (
        <>
            <SideLink name="Dashboard" link="/dashboard" />
            <SideLink name="Pengguna" link="/dashboard/pengguna" />
            <SideLink name="Slide Show" link="/dashboard/slideshow" />
            <SideLink name="Galery" link="/dashboard/galery" />
            <SideLink name="Layanan" link="/dashboard/layanan" />
            <CustomAccording name="Data Loker" heigth="h-[90px]" activeLink={currentPath.startsWith('/dashboard/loker') || currentPath.startsWith('/dashboard/lamaran/kerja')}>
                <SideLink name="Lowongan Kerja" link="/dashboard/loker" />
                <SideLink name="Data Lamaran" link="/dashboard/lamaran/kerja" />
            </CustomAccording>
            {/* <CustomAccording name="Data Magang" heigth="h-[90px]" activeLink={currentPath.startsWith('/dashboard/magang') || currentPath.startsWith('/dashboard/lamaran/magang')} >
                    <SideLink name="Lowongan Magang" link="/dashboard/magang" />
                    <SideLink name="Data Lamaran" link="/dashboard/lamaran/magang" />
                </CustomAccording> */}
            <SideLink name="Magang" link="/dashboard/magang" />
            <SideLink name="Sertifikasi" link="/dashboard/sertifikasi" />
            <SideLink name="Workshop" link="/dashboard/workshop" />
            <SideLink name="Pelatihan" link="/dashboard/pelatihan" />
            <SideLink name="Pengaturan" link="/dashboard/pengaturan" />
        </>
    )
}

export default SidebarAdmin