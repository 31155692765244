const InputDefault = ({ id, type, placeholder, label, value, setValue, handleChange, errorMessage, disabled, inputMode }) => {
    return (
        <div className="w-full flex flex-col text-xs md:text-sm lg:text-base text-black">
            <label htmlFor={id} className="font-semibold capitalize">{label}</label>
            <input 
                type={type ?? "text"} 
                id={id} 
                value={value} 
                placeholder={placeholder}
                onChange={handleChange ? handleChange : (e) => setValue(e.target.value)}
                className="px-3 py-2 rounded-md border-[2px] outline-none border-gray-200 focus:border-blue-500 text-black text-xs md:text-sm lg:text-base" 
                disabled={disabled}
                inputMode={inputMode ?? "text"}
            />
            {errorMessage && <p className="text-sm text-red-500 lowercase">{errorMessage}</p>}
        </div>
    )
}

export default InputDefault