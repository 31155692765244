const TextAreaDefault = ({ id, label, value, setValue, handleChange, cols, rows, errorMessage }) => {
    return (
        <div className="flex flex-col">
            <label htmlFor={id} className="font-semibold capitalize">{label}</label>
            <textarea id={id}
                className="border-[2px] outline-none  border-gray-200 focus:border-blue-500 rounded-xl text-black text-xs md:text-sm lg:text-base" cols={cols ?? 5} rows={rows ?? 5}
                value={value}
                onChange={handleChange ? handleChange : (e) => setValue(e.target.value)}
            />
             {errorMessage && <p className="text-sm text-red-500 lowercase">{errorMessage}</p>}
        </div>
    )
}

export default TextAreaDefault