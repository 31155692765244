import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { getUser } from "../../../services/pengguna"
import { BaseURL } from './../../../services/base_url';
import { faChevronLeft, faChevronRight, faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons"
import InputSearch from "../../../components/forms/input_search"
import CustomPaginate from "../../../components/customs/paginate"
import SelectDefault from './../../../components/forms/select_default';
import { getRegistrasionCategory, getRegistrasionSubCategory } from "../../../services/registration_service"

const Pengguna = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModalCreate, setOpenModalCreate] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)

    const [keyword, setKeyword] = useState("")
    const fetchData = async (accessToken, keyword, page) => {
        setLoading(true)
        try {
            const response = await getUser(accessToken, keyword, page, categoryId, subCategoryId)
            if (response.status === 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setFrom(response.data.meta.from)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    const [dataCategory, setDataCategory] = useState([])
    const [categoryId, setCategoryId] = useState(null)
    const fetchDataCategory = async () => {
        try {
            const response = await getRegistrasionCategory()
            if (response.status === 200) {
                setDataCategory(response.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [dataSubCategory, setDataSubCategory] = useState([])
    const [subCategoryId, setSubCategoryId] = useState(null)
    const fetchDataSubCategory = async (categoryId) => {
        try {
            const response = await getRegistrasionSubCategory(categoryId)
            if (response.status === 200) {
                setDataSubCategory(response.data)
            }
        } catch (error) {
            console.log("__")
        }
    }
    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, keyword, currentPage)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-gentrakarya');
        setAccessToken(getAccessToken)
        fetchData(getAccessToken, keyword, currentPage)
        fetchDataCategory()
    }, [])

    const handleSearch = (e) => {
        setKeyword(e.target.value)
        setCurrentPage(1)
        fetchData(accessToken, e.target.value, 1)
    }

    const handleCategory = (e) => {
        const newCategoryId = e
        setCategoryId(newCategoryId)
        setSubCategoryId(null)
        fetchDataSubCategory(newCategoryId)
        setCurrentPage(1)
        setRefresh(true)
    }

    const handleSubCategory = (e) => {
        const newSubCategoryId = e
        setSubCategoryId(newSubCategoryId)
        setCurrentPage(1)
        setRefresh(true)
    }

    const handlePaginate = (newPage) => {
        fetchData(accessToken, keyword, newPage)

    }

    return (
        <>
            <Helmet>Data Pengguna - GENTRAKARYA</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Data Pengguna</p>
                    <p>{totalData} Pengguna</p>
                </CustomHeader>

                <div className="p-3 bg-white sticky top-16 left-0 z-10 flex flex-col md:flex-row items-center justify-between gap-3">
                    <InputSearch value={keyword} handleChange={handleSearch} addClass="w-full md:w-auto" />

                    <div className="grid grid-cols-2 lg:flex items-center gap-3 z-20 sticky top-16 left-0 w-full md:w-auto">
                        <SelectDefault
                            placeholder="Kategori"
                            options={dataCategory?.map(item => ({ value: item.id, label: item.name }))}
                            value={categoryId} onSelect={(e) => handleCategory(e)}
                            width="w-[300px]"
                            addOption={
                                <button onClick={() => handleCategory(null)} className="px-3 py-2 border-b-2 rounded-full hover:bg-gray-200 transition-colors text-start">
                                    Semua
                                </button>
                            }
                        />

                        <SelectDefault
                            placeholder="Sub Kategori"
                            options={dataSubCategory?.map(item => ({ value: item.id, label: item.name }))}
                            value={subCategoryId} onSelect={(e) => handleSubCategory(e)}
                            width="w-[300px]"
                            capitalize="uppercase"
                            addClass="uppercase"
                            addOption={
                                <button onClick={() => handleSubCategory(null)} className="px-3 py-2 border-b-2 rounded-full hover:bg-gray-200 transition-colors text-start">
                                    Semua
                                </button>
                            }
                            position="right-0"
                        />
                    </div>
                </div>

                <div className="w-full px-3 pb-5">
                    <table className="max-w-screen-2xl min-w-max md:w-full text-sm md:text-base text-black">
                        <thead className="sticky top-[130px] ">
                            <tr className="bg-blue-300">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Profile</th>
                                <th className="px-3 py-2">Nama</th>
                                <th className="px-3 py-2">Email</th>
                                <th className="px-3 py-2">Kategori</th>
                                <th className="px-3 py-2">Sub Kategori</th>
                                <th className="px-3 py-2 rounded-tr-xl">No Telepon</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..!" :
                                    data?.map((item) => (
                                        <tr className={`hover:bg-gray-300 transition-colors ${from % 2 === 0 ? 'bg-gray-200' : ''}`}>
                                            <td className="px-3 py-2 text-center">{from++}</td>
                                            <td className="px-3 py-2 ">
                                                {item?.image === null ?
                                                    <div className="w-14 aspect-square bg-gray-400 rounded-full flex items-center justify-center font-semibold mx-auto">
                                                        {item?.name?.charAt(0)}
                                                    </div> :
                                                    <img
                                                        src={BaseURL + "storage/" + item?.image}
                                                        alt="Gentra Karya"
                                                        className="w-14 aspect-square rounded-full object-cover mx-auto bg-gray-400" />
                                                }
                                            </td>
                                            <td className="px-3 py-2">{item?.name}</td>
                                            <td className="px-3 py-2">{item?.email}</td>
                                            <td className="px-3 py-2">{item?.registration_category?.name ?? ""}</td>
                                            <td className="px-3 py-2">{item?.registration_sub_category?.name ?? "-"}</td>
                                            <td className="px-3 py-2">{item?.phone_number ?? "-"}</td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="md:px-3 sticky bottom-0 left-0">
                    <div className="flex items-center justify-center md:justify-end w-full bg-blue-300 px-3 py-2 ">
                        <CustomPaginate currentPage={currentPage} lastPage={lastPage} handlePaginate={handlePaginate} />
                    </div>
                </div>
            </LayoutDashboard>
        </>
    )
}

export default Pengguna