import apiService from "./api_service";

export const getTraining = async (accessToken, category, provinceId, regencyId, districtId, villageId, keyword, page) => {
    try {
        const response = apiService.get(`training`, {
            params: {
                category: category,
                province_id: provinceId,
                regency_id: regencyId,
                district_id: districtId,
                village_id: villageId,
                keyword: keyword,
                page: page
            },
            headers: {  
                Authorization: accessToken ?? null,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
        console.error('Error fetching data:', error);
    }
}

export const getSampleTraining = async () => {
    try {
        const response = apiService.get('training/sample', {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}

export const showTraining = async (id) => {
    try {
        const response = await apiService.get(`training/show/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const postTraining = async (accessToken, data ) => {
    try {
        const response = await apiService.post(`training/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const deleteTraining = async (accessToken, id) => {
    try {
        const response = await apiService.post(`training/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    }
    catch (error) {
        return error.response
    }
};