import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from "../../../components/buttons/back_button"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { deleteTraining, showTraining } from "../../../services/training_service"
import { useEffect } from "react"
import CardTrainingDetail from './../../../components/cards/training_detail';
import { getRegistrasionCategory } from './../../../services/registration_service';
import BtnDefault from "../../../components/buttons/default_button"
import CustomSpinner from "../../../components/customs/spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

const TrainingDetail = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [registrationCategory, setRegistrationCategory] = useState(null)
    const navigate = useNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState(null)
    const fetchData = async (id) => {
        setLoading(true)
        try {
            const response = await showTraining(id);
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        try {
            const getAccessToken = localStorage.getItem('access_token-gentrakarya')
            const getRegistrasionCategory = localStorage.getItem('registration_category-gentrakarya')

            setAccessToken(getAccessToken)
            setRegistrationCategory(getRegistrasionCategory)

            fetchData(id)
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
    }, [])

    const [loadingDelete, setLoadingDelete] = useState(false)
    const handleDelete = async () => {
        setLoadingDelete(true)
        try {
            const response = await deleteTraining(accessToken, id)
            if (response.status === 200) {
                navigate('/dashboard/pelatihan')
            } else {
                alert("Gagal menghapus data..!")
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingDelete(false)
    }

    return (
        <>
            <Helmet>Detail Pelatihan</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p>Detail Pelatihan</p>
                    <BtnDefault handleClick={handleDelete} addClass="bg-red-500 hover:bg-red-600">
                        {loadingDelete ? <CustomSpinner color="bg-white" /> : <>
                            <FontAwesomeIcon icon={faTrash} />
                            <p className="hidden lg:flex">Hapus Pelatihan</p>
                        </>}
                    </BtnDefault>
                </CustomHeader>
                <CardTrainingDetail accessToken={accessToken} id={id} category={registrationCategory} />

            </LayoutDashboard>
        </>
    )
}

export default TrainingDetail