import { useState } from 'react';
import InputDefault from '../../../components/forms/input_default';
import LayoutModal from '../../../components/layouts/modal';
import BtnDefault from '../../../components/buttons/default_button';
import CustomSpinner from '../../../components/customs/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { updateProfileImage } from '../../../services/profile_service';
import { useEffect } from 'react';
import { BaseURL } from '../../../services/base_url';

const UpdatePhotoProfile = ({ accessToken, open, setOpen, getImage, setRefresh }) => {
    const [loading, setLoading] = useState(false)

    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)

    const [errorImage, setErrorImage] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorImage(null)
            const data = new FormData()
            data.append('image', image)
            const response = await updateProfileImage(accessToken, data)
            if (response.status === 200) {
                setRefresh(true)
                setOpen(false)
                setImage(null)
                setImagePreview(null)
            } else {
                alert('Gagal update foto profil..!')
            }
        } catch (error) {
            alert('Silahkan cek oneksi internet Anda..!')
        }
        setLoading(false)
    }

    return (
        <LayoutModal name="Update Foto Profil" showModal={open} width="w-[90%] md:w-[500px]" height="h-[450px]">
            <form onSubmit={handleSubmit} className='px-3 flex flex-col gap-3'>
                <div className='flex flex-col items-center justify-center gap-3'>
                    <label htmlFor="image" className='border-2 border-dotted border-black rounded-xl px-3 py-2 flex items-center gap-3 cursor-pointer'>
                        <FontAwesomeIcon icon={faUpload} />
                        <p>Upload Image</p>
                    </label>
                    <input
                        id='image'
                        type="file"
                        accept="image/*"
                        className='hidden'
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setImage(file)
                            if (file) {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setImagePreview(reader.result);
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                    {
                        imagePreview ? <img src={imagePreview} alt="Preview" className="w-[200px] aspect-square object-cover rounded-xl border shadow-md" /> :
                            getImage && getImage.length > 1 ? <img src={BaseURL + "storage/" + getImage} alt="Foto Profil - GENTRAKARYA" className="w-[200px] aspect-square object-cover rounded-xl border shadow-md" /> :
                                <div className='w-[200px] aspect-square object-cover rounded-xl border shadow-md flex items-center justify-center font-bold text-5xl'>{getImage}</div>

                    }
                </div>
                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>
                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UpdatePhotoProfile