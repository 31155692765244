import apiService from "./api_service";

export const getProfileLembaga = async (accessToken) => {
    try {
        const response = await apiService.get(`profile-lembaga`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const postProfileLembaga = async (accessToken, data) => {
    try {
        const response = await apiService.post(`profile-lembaga/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const updateLembaga = async (accessToken, data) => {
    try {
        const response = await apiService.post(`profile-lembaga/update`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const updateLembagaAddress = async (accessToken, data) => {
    try {
        const response = await apiService.post(`profile-lembaga/update/address`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const updateProfileLembagaImage = async (accessToken, data) => {
    try {
        const response = await apiService.post(`profile-lembaga/update/image`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};