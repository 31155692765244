import { Helmet } from "react-helmet"
import LayoutLandingPage from "../../../components/layouts/landing_page"
import SelectFilter from "../../../components/forms/select_filter"
import { getDistrict, getProvince, getRegency, getVillage } from "../../../services/location_service"
import { useEffect } from "react"
import { useState } from "react"
import CustomPaginate from "../../../components/customs/paginate"
import InputSearch from "../../../components/forms/input_search"
import Footer from "../sections/footer"
import CustomSpinner from "../../../components/customs/spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/free-solid-svg-icons"
import { getWorkshop } from './../../../services/workshop_service';
import LoadingWorkshop from "../../../components/loadings/workshop"
import CardWorkshop from "../../../components/cards/workshop"

const WorkshopLp = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [totalData, setTotalData] = useState(0)

    const [keyword, setKeyword] = useState("")
    const fetchData = async (provinceId, regencyId, districtId, villageId, keyword, currentPage) => {
        setLoading(true)
        try {
            const response = await getWorkshop(null, null, provinceId, regencyId, districtId, villageId, keyword, currentPage);
            if (response.status === 200) {
                setData(response.data.data)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setTotalData(response.data.meta.total)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    const [province, setProvince] = useState([]);
    const [provinceId, setProvinceId] = useState(null);
    const fetchDataProvince = async () => {
        try {
            const response = await getProvince()
            if (response.status === 200) {
                setProvince(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [regency, setRegency] = useState([]);
    const [regencyId, setRegencyId] = useState(null);
    const fetchDataRegency = async (provinceId) => {
        try {
            const response = await getRegency(provinceId)
            if (response.status === 200) {
                setRegency(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState(null);
    const fetchDataDistrict = async (regencyId) => {
        try {
            const response = await getDistrict(regencyId)
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [village, setVillage] = useState([]);
    const [villageId, setVillageId] = useState(null);
    const fetchDataVillage = async (districtId) => {
        try {
            const response = await getVillage(districtId)
            if (response.status === 200) {
                setVillage(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        if (refresh) {
            fetchData(provinceId, regencyId, districtId, villageId, keyword, currentPage)
            setRefresh(false)
        }

    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-gentrakarya');
        setAccessToken(getAccessToken);
        fetchData(provinceId, regencyId, districtId, villageId, keyword, currentPage)
        fetchDataProvince()
    }, [])

    const handleProvince = (e) => {
        const provinceId = e;
        setProvinceId(provinceId);
        fetchDataRegency(provinceId)

        setDistrict([]);
        setDistrictId(0);

        setVillage([]);
        setVillageId(0);

        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleRegency = (e) => {
        const regencyId = e;
        setRegencyId(regencyId);

        fetchDataDistrict(regencyId)

        setVillage([]);
        setVillageId(0);

        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleDistrict = (e) => {
        const districtId = e;
        setDistrictId(districtId);

        fetchDataVillage(districtId)
        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );
    };

    const handleVillage = (e) => {
        const vilageId = e;
        setVillageId(vilageId);

        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            currentPage
        );

    };

    const handleSearch = (e) => {
        setKeyword(e.target.value)
        setCurrentPage(1)
        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            e.target.value,
            1
        );
    };

    const handlePaginate = (newPage) => {
        fetchData(
            provinceId,
            regencyId,
            districtId,
            villageId,
            keyword,
            newPage
        );
    }
    return (
        <>
            <Helmet>Lowongan Magang</Helmet>
            <LayoutLandingPage>

                <div className="sticky top-16 z-10 h-14 flex items-center justify-between px-10 text-sm border-b-2 bg-blue-300 space-x-3">
                    <div className="flex items-center space-x-3">
                        <FontAwesomeIcon
                            icon={faBell}
                            className="h-8 aspect-square text-black"
                        />
                        <p className="text-base md:text-2xl font-semibold">
                            Informasi Workshop - Gantra Karya
                        </p>
                    </div>

                    {loading === true ? (
                        <CustomSpinner />
                    ) : (
                        <div className="flex items-center space-x-2 font-semibold">
                            <p className="text-2xl">
                                <span className="md:hidden">(</span>
                                <span>{totalData}</span>
                                <span className="md:hidden">)</span>
                            </p>
                            <p className="hidden md:block text-base">Total Data</p>
                        </div>
                    )}
                </div>

                <div className=" flex flex-col lg:flex-row items-center justify-between gap-3 p-3 sticky top-16 border-b-2 bg-white border-black z-10">
                    <div class="w-full md:w-[300px]">
                        <InputSearch value={keyword} handleChange={handleSearch} addClass="w-full" />
                    </div>

                    <div className="w-full grid grid-cols-2 md:grid-cols-4 gap-2">
                        <SelectFilter
                            placeholder="Pilih provinsi"
                            options={province.map(item => ({ value: item.id, label: item.name }))}
                            value={provinceId} onSelect={(e) => handleProvince(e)}
                            addClass="w-full"
                            addOption={
                                <button onClick={() => handleProvince(null)} className="px-3 py-2 border-b-2 w-full text-start hover:bg-slate-200 transition-colors">
                                    Pilih Provinsi
                                </button>
                            }
                            widthContent="w-[350px]"
                            positionContent=""
                        />

                        <SelectFilter
                            placeholder="Pilih provinsi"
                            options={regency.map(item => ({ value: item.id, label: item.name }))}
                            value={regencyId} onSelect={(e) => handleRegency(e)}
                            addClass="w-full"
                            addOption={
                                <button onClick={() => handleRegency(null)} className="px-3 py-2 border-b-2 w-full text-start hover:bg-slate-200 transition-colors">
                                    Pilih Kabupaten / Kota
                                </button>
                            }
                            widthContent="w-[350px]"
                            positionContent="right-0 md:left-0"
                        />

                        <SelectFilter
                            placeholder="Pilih kecamatan"
                            options={district.map(item => ({ value: item.id, label: item.name }))}
                            value={districtId} onSelect={(e) => handleDistrict(e)}
                            addOption={
                                <button onClick={() => handleDistrict(null)} className="px-3 py-2 border-b-2 w-full text-start hover:bg-slate-200 transition-colors">
                                    Pilih Kecamatan
                                </button>
                            }
                            widthContent="w-[350px]"
                            positionContent=""
                        />

                        <SelectFilter
                            placeholder="Pilih desa / kelurahan"
                            options={village.map(item => ({ value: item.id, label: item.name }))}
                            value={villageId} onSelect={(e) => handleVillage(e)}
                            addOption={
                                <button onClick={() => handleVillage(null)} className="px-3 py-2 border-b-2 w-full text-start hover:bg-slate-200 transition-colors">
                                    Pilih Desa / Kelurahan
                                </button>
                            }
                            widthContent="w-[350px]"
                            positionContent="right-0"
                        />
                    </div>
                </div>

                <div className="w-full px-5 md:px-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-5">
                    {loading === true ? (
                        <>
                            {Array.from({ length: 3 }, (_, index) => (
                                <LoadingWorkshop />
                            ))}
                        </>
                    ) : (
                        <>
                            {data?.map((item) => (
                                <CardWorkshop
                                    link={`detail/${item?.id}`}
                                    title={item?.title}
                                    lembagaName={item?.lembaga?.name}
                                    lembagaCategory={item?.lembaga?.category?.name ?? "-"}
                                    cost={item?.workshop_cost}
                                    metoda={item?.metoda?.name}
                                    implementationDate={item?.implementation_date}
                                    createdAt={item?.created_at}
                                />
                            ))}
                        </>
                    )}
                </div>

                {lastPage > 1 &&
                    <div className="flex items-center justify-end w-full bg-blue-300 px-3 py-2 sticky">
                        <CustomPaginate currentPage={currentPage} lastPage={lastPage} handlePaginate={handlePaginate} />
                    </div>
                }

                <Footer />
            </LayoutLandingPage>
        </>
    )
}


export default WorkshopLp