import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from './../../../components/buttons/back_button';
import BtnDefault from "../../../components/buttons/default_button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import InputDefault from "../../../components/forms/input_default";
import { useState } from "react";
import SelectFilter from "../../../components/forms/select_filter";
import { useEffect } from "react";
import { getDistrict, getProvince, getRegency, getVillage } from "../../../services/location_service";
import { useNavigate } from "react-router-dom";
import TextAreaDefault from "../../../components/forms/text_area_default";
import { postProfileLembaga } from "../../../services/profile_lembaga_service";
import CustomSpinner from "../../../components/customs/spinner";

const CreateLembaga = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [registrationCategory, setRegistrationCategory] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [image, setImage] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)

    const [name, setName] = useState("")
    const [resonsibleName, setResponsibleName] = useState("")
    const [description, setDescription] = useState("")

    const [map, setMap] = useState("")
    const [detailAddress, setDetailAddress] = useState("")

    const [errorImage, setErrorImage] = useState(null)
    const [errorName, setErrorName] = useState(null)
    const [errorResponsibleName, setErrorResponsibleName] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)
    const [errorMap, setErrorMap] = useState(null)
    const [errorDetailAddress, setErrorDetailAddress] = useState(null)

    const [errorProvince, setErrorProvince] = useState(null);
    const [errorRegency, setErrorRegency] = useState(null);
    const [errorDistrict, setErrorDistrict] = useState(null);
    const [errorVillage, setErrorVillage] = useState(null);

    const [province, setProvince] = useState([]);
    const [provinceId, setProvinceId] = useState(null);
    const fetchDataProvince = async () => {
        try {
            const response = await getProvince()
            if (response.status === 200) {
                setProvince(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [regency, setRegency] = useState([]);
    const [regencyId, setRegencyId] = useState(null);
    const fetchDataRegency = async (provinceId) => {
        try {
            const response = await getRegency(provinceId)
            if (response.status === 200) {
                setRegency(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState(null);
    const fetchDataDistrict = async (regencyId) => {
        try {
            const response = await getDistrict(regencyId)
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [village, setVillage] = useState([]);
    const [villageId, setVillageId] = useState(null);
    const fetchDataVillage = async (districtId) => {
        try {
            const response = await getVillage(districtId)
            if (response.status === 200) {
                setVillage(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        try {
            const getAccessToken = localStorage.getItem('access_token-gentrakarya')
            setAccessToken(getAccessToken)
            fetchDataProvince()
        } catch (error) {
            alert("Silahkan periksa koneksi internet Anda..!");
        }
        // eslint-disable-next-line
    }, []);

    const handleProvince = (e) => {
        const provinceId = e
        setProvinceId(provinceId);
        fetchDataRegency(provinceId)
        setRegencyId(null)
        setDistrict([]);
        setDistrictId(null);
        setVillage([]);
        setVillageId(null);
    };

    const handleRegency = (e) => {
        const regencyId = e
        setRegencyId(regencyId);
        fetchDataDistrict(regencyId)
        setVillage([]);
        setVillageId(null);
    };

    const handleDistrict = (e) => {
        const districtId = e
        setDistrictId(districtId);
        fetchDataVillage(districtId)
    };

    const handleVillage = (e) => {
        const vilageId = e
        setVillageId(vilageId);
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorImage(null)
            setErrorName(null)
            setErrorResponsibleName(null)
            setErrorDescription(null)

            setErrorProvince(null)
            setErrorRegency(null)
            setErrorDistrict(null)
            setErrorVillage(null)
            setErrorMap(null)
            setErrorDetailAddress(null)

            if (!image) {
                setErrorImage("Image tidak boleh kosong..!")
            } else if (name.length === 0) {
                setErrorName("Nama tidak boleh kosong..!")
            } else if (name.length < 3) {
                setErrorName("Nama minimal terdiri dari 3 huruf..!")
            } else if (resonsibleName.length === 0) {
                setErrorResponsibleName("Nama penanggung jawab tidak boleh kosong..!")
            } else if (resonsibleName.length < 3) {
                setErrorResponsibleName("Name penanggung jawab minimal terdiri dari 3 huruf..!")
            } else if (description.length < 3) {
                setErrorDescription("Deskripsi tidak boleh kosong..!")
            } else if (provinceId === null) {
                setErrorProvince("Silahkan pilih provinsi terlebih dahulu..!")
            } else if (regencyId === null) {
                setErrorRegency("Silahkan pilih kabupaten/kota terlebih dahulu..!")
            } else if (districtId === null) {
                setErrorDistrict("Silahkan pilih kecamatan terlebih dahulu..!")
            } else if (villageId === null) {
                setErrorVillage("Silahkan pilih desa / kelurahan terlebih dahulu..!")
            } else if (map.length === 0) {
                setErrorMap("Link google map tidak boleh kosong..!")
            } else if (detailAddress.length === 0) {
                setErrorDetailAddress("Detail alamat tidak boleh kosong..!")
            } else {

                const formData = new FormData()
                formData.append('image', image)
                formData.append('name', name)
                formData.append('responsible_name', resonsibleName)
                formData.append('description', description)

                formData.append('province_id', provinceId);
                formData.append('regency_id', regencyId);
                formData.append('district_id', districtId);
                formData.append('village_id', villageId);
                formData.append('map', map);
                formData.append('detail_address', detailAddress);

                const response = await postProfileLembaga(accessToken, formData)
                if (response.status === 201) {
                    navigate('/dashboard/pengaturan')
                } else if (response.status === 403) {
                    alert('Anda bukan Lembaga, Anda tidak diperbolehkan mengatur profil Lembaga..!')
                } else {
                    alert('gagal menyimpan data..!')
                }
            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }

    return (
        <>
            <Helmet>Atur Lembaga</Helmet>
            <LayoutDashboard>
                <form onSubmit={handleSubmit}>
                    <CustomHeader>
                        <BtnBack />
                        <p>Atur Lembaga</p>
                        <BtnDefault type="submit">
                            {loading ? <CustomSpinner color="bg-white" /> :
                                <>
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p>Simpan Data</p>
                                </>
                            }
                        </BtnDefault>
                    </CustomHeader>

                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 p-3">
                        <div className="w-full flex flex-col gap-3">
                            <div className="w-full ">
                                <label htmlFor="image" className="h-[220px] aspect-video border-[3px] border-black border-dashed rounded-xl  flex flex-col items-center justify-center mx-auto cursor-pointer font-semibold gap-3 relative overflow-hidden">
                                    {!imagePreview ?
                                        <>
                                            <FontAwesomeIcon icon={faUpload} className="text-5xl" />
                                            <p>Upload Gambar</p>
                                        </> :
                                        <img src={imagePreview} alt="Preview" className="w-full aspect-vidio object-cover rounded-xl border shadow-md" />
                                    }
                                </label>
                                <input
                                    id='image'
                                    type="file"
                                    accept="image/*"
                                    className='hidden'
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        setImage(file)
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                setImagePreview(reader.result);
                                            };
                                            reader.readAsDataURL(file);
                                        }
                                    }}
                                />

                                {errorImage && <p className="text-sm text-red-500 lowercase text-center">{errorImage}</p>}
                            </div>
                            <InputDefault id="name" label="Nama Lembaga / Perusahaan" value={name} setValue={setName} errorMessage={errorName} />
                            <InputDefault
                                id="responsibbleName" label="Nama Penanggung Jawab"
                                value={resonsibleName} setValue={setResponsibleName} errorMessage={errorResponsibleName}
                            />
                            <TextAreaDefault
                                id="description" label="Deskripsi Lembaga / Perusahaan"
                                value={description} setValue={setDescription}
                                errorMessage={errorDescription}
                            />

                        </div>

                        <div className=" flex flex-col gap-3">
                            <SelectFilter
                                name="Pilih Provinsi" placeholder="Pilih provinsi"
                                options={province.map(item => ({ value: item.id, label: item.name }))}
                                value={provinceId} onSelect={(e) => handleProvince(e)}
                                errorMessage={errorProvince}

                            />
                            <SelectFilter
                                name="Pilih Kabupaten / Kota" placeholder="Pilih kabupaten / kota"
                                options={regency.map(item => ({ value: item.id, label: item.name }))}
                                value={regencyId} onSelect={(e) => handleRegency(e)}
                                errorMessage={errorRegency}
                            />

                            <SelectFilter
                                name="Pilih Kecamatan" placeholder="Pilih kecamatan"
                                options={district.map(item => ({ value: item.id, label: item.name }))}
                                value={districtId} onSelect={(e) => handleDistrict(e)}
                                errorMessage={errorDistrict}
                            />

                            <SelectFilter
                                name="Pilih Desa / Kelurahan" placeholder="Pilih desa / kelurahan"
                                options={village.map(item => ({ value: item.id, label: item.name }))}
                                value={villageId} onSelect={(e) => handleVillage(e)}
                                errorMessage={errorVillage}
                            />

                            <InputDefault id="map" label="Link Google Map" value={map} setValue={setMap} errorMessage={errorMap} />

                            <TextAreaDefault id="detailAddress" label="Detail Alamat" value={detailAddress} setValue={setDetailAddress} />
                        </div>
                    </div>
                </form>
            </LayoutDashboard >
        </>
    )
}

export default CreateLembaga