import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import AdminCreate from "./create"
import { deleteAdmin, getAdmin } from "../../../services/admin_service"
import InputSearch from './../../../components/forms/input_search';
import { BaseURL } from "../../../services/base_url"
import CustomPaginate from './../../../components/customs/paginate';
import SelectDefault from './../../../components/forms/select_default';
import CustomAlert from "../../../components/customs/alert"
import CustomSpinner from "../../../components/customs/spinner"

const Admin = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModalCreate, setOpenModalCreate] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)

    const [keyword, setKeyword] = useState("")
    const [category, setCategory] = useState(null)
    const fetchData = async (accessToken, keyword, page, category) => {
        setLoading(true)
        try {
            const response = await getAdmin(accessToken, keyword, page, category)
            if (response.status === 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setFrom(response.data.meta.from)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, keyword, currentPage, category)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-gentrakarya');
        setAccessToken(getAccessToken)
        fetchData(getAccessToken, keyword, currentPage, category)
    }, [])

    const handleSearch = (e) => {
        setKeyword(e.target.value)
        setCurrentPage(1)
        fetchData(accessToken, e.target.value, 1, category)
    }

    const handlePaginate = (newPage) => {
        fetchData(accessToken, keyword, newPage)

    }

    const categoryOption = [
        { 'name': 'admin' },
        { 'name': 'super admin' }
    ]

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState("")
    const [typeAlert, setTypeAlert] = useState("success")

    const [loadingDelete, setLoadingDelete] = useState(false)
    const handleDelete = async (adminId) => {
        setLoadingDelete(true)
        try {
            const response = await deleteAdmin(accessToken, adminId)
            if (response.status === 200) {
                setRefresh(true)
                setShowAlert(true)
                setMessage("Berhasil menghapus data..!")
                setTypeAlert("success")
            } else {
                setShowAlert(true)
                setMessage(response?.data?.message ?? "Gagal menghapus data..!")
                setTypeAlert("danger")
            }
        } catch (error) {
            setShowAlert(true)
            setMessage("Gagal menghapus data..!")
            setTypeAlert("danger")
        }
        setLoadingDelete(false)
    }

    return (
        <>
            <Helmet>Data Admin - GENTRAKARYA</Helmet>
            <LayoutDashboard>
                <CustomHeader className="sticky top-0 left-0 z-10">
                    <p>Data Admin</p>
                    <button type="button"
                        onClick={() => setOpenModalCreate(true)}
                        className="px-3 py-2 bg-blue-500 text-white rounded-md flex items-center justify-center gap-3">
                        <FontAwesomeIcon icon={faPlus} />
                        <p>Admin Baru</p>
                    </button>
                </CustomHeader>

                <div className="p-3 bg-white sticky top-16 left-0 z-10 flex items-center justify-between">
                    <InputSearch value={keyword} handleChange={handleSearch} />

                    <div>
                        <SelectDefault
                            placeholder="Kategori"
                            options={categoryOption?.map(item => ({ value: item.name, label: item.name }))}
                            value={category} onSelect={(e) => {
                                setCategory(e)
                                setRefresh(true)
                            }}
                            addOption={
                                <button onClick={() => {
                                    setCategory("")
                                    setRefresh(true)
                                }} className="border-b text-start px-3 py-2 hover:bg-gray-200 rounded-full">
                                    Semua
                                </button>
                            }
                            width="w-[200px]"
                            position="right-0"
                        />
                    </div>
                </div>

                <div className="w-full px-3 pb-5">
                    <table className="max-w-screen-2xl min-w-max md:w-full text-sm md:text-base text-black">
                        <thead className="sticky top-[130px] ">
                            <tr className="bg-blue-300">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Profile</th>
                                <th className="px-3 py-2">Nama</th>
                                <th className="px-3 py-2">Email</th>
                                <th className="px-3 py-2">Kategori</th>
                                <th className="px-3 py-2">No Telepon</th>
                                <th className="px-3 py-2 rounded-tr-xl">Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..!" :
                                    data?.map((item) => (
                                        <tr className={`hover:bg-gray-300 transition-colors ${from % 2 === 0 ? 'bg-gray-200' : ''}`}>
                                            <td className="px-3 py-2 text-center">{from++}</td>
                                            <td className="px-3 py-2 ">
                                                {item?.image === null ?
                                                    <div className="w-14 aspect-square bg-gray-200 rounded-full flex items-center justify-center font-semibold mx-auto uppercase text-xl">
                                                        {item?.name?.charAt(0)}
                                                    </div> :
                                                    <img src={BaseURL + "storage/" + item?.image} alt="Foto Profile Pengguna - Gentra Karya" className="w-14 aspect-square rounded-full object-cover mx-auto" />
                                                }
                                            </td>
                                            <td className="px-3 py-2">{item?.name}</td>
                                            <td className="px-3 py-2">{item?.email}</td>
                                            <td className="px-3 py-2">{item?.registration_category?.name ?? ""}</td>
                                            <td className="px-3 py-2">{item?.phone_number ?? "-"}</td>
                                            <td className="px-3 py-2">
                                                <div className="dropdown">
                                                    <label className="btn btn-solid-error bg-red-500 hover:bg-red-600 text-white my-2" tabIndex="0">
                                                        {loadingDelete ? <CustomSpinner color="" /> : <FontAwesomeIcon icon={faTrash} />}
                                                    </label>
                                                    <div className="dropdown-menu w-72 z-50 bg-gray-50 text-black">
                                                        <div className="p-5 flex flex-col items-center justify-center space-y-3 z-50 ">
                                                            <svg width="60" height="60" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM24 26C22.9 26 22 25.1 22 24V16C22 14.9 22.9 14 24 14C25.1 14 26 14.9 26 16V24C26 25.1 25.1 26 24 26ZM26 34H22V30H26V34Z" fill="#E92C2C" />
                                                            </svg>
                                                            <p className="text-center">Apakah Anda yakin akan menghapus ini..?</p>
                                                            <div className="flex items-center space-x-3 text-sm text-white">
                                                                <label className="btn btn-success w-32">
                                                                    Tidak
                                                                </label>

                                                                <button onClick={() => handleDelete(item?.id)} className="btn btn-error w-32">
                                                                    {loadingDelete ? <CustomSpinner color="bg-white" /> : "Ya"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className="md:px-3 sticky bottom-0 left-0">
                    <div className="flex items-center justify-center md:justify-end w-full bg-blue-300 px-3 py-2 ">
                        <CustomPaginate currentPage={currentPage} lastPage={lastPage} handlePaginate={handlePaginate} />
                    </div>
                </div>

            </LayoutDashboard>

            <CustomAlert
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                message={message}
                type={typeAlert}
            />

            {/* Modal */}
            <AdminCreate accessToken={accessToken} open={openModalCreate} setOpen={setOpenModalCreate} setRefresh={setRefresh} />
        </>
    )
}

export default Admin