import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/dashboard"
import CustomHeader from "../../../../components/customs/header"

const LamaranKerja = () => {
    return (
        <>
            <Helmet>Lamaran Kerja</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Data Lamaran Kerja</p>
                </CustomHeader>

                <div className="w-full h-screen flex items-center justify-center -mt-16">
                    <p className="text-3xl font-semibold uppercase">Dalam Perbaikan..!</p>
                </div>

            </LayoutDashboard>
        </>
    )
}

export default LamaranKerja