import apiService from "./api_service";

export const getDashboardService = async ( accessToken) => {
    try {
        const response = await apiService.get(`dashboard`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

