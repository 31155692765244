import { Link } from "react-router-dom"

const BtnDefault = ({ children, type, handleClick, addClass }) => {
    return (
        <button type={type ?? "button"} onClick={handleClick} className={`px-3 py-2 rounded-md bg-blue-500 hover:bg-blue-600 transition-colors text-white flex items-center justify-center font-semibold gap-3 ${addClass}`}>
            {children}
        </button>
    )
}

export default BtnDefault