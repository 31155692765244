
import { Helmet } from 'react-helmet';
import LayoutLandingPage from '../../components/layouts/landing_page';
import WelcomeSection from './sections/welcome_section';
import ProfileSection from './sections/profile_section';
import VisiMisi from './sections/visi_misi';
import MyCarousel from './sections/carousel';
import Footer from './sections/footer';
import SampleLoker from './sections/sample_loker';
import SamplePelatihan from './sections/sample_pelatihan';
import SampleSertiikasi from './sections/sample_sertifikasi';
import SampleWorkshop from './sections/sample_workshop';
import SampleMagang from './sections/sample_magang';

const LandingPage = () => {
    return (
        <>
            <Helmet>GENTRAKARYA</Helmet>
            <LayoutLandingPage>
                <WelcomeSection />
                <ProfileSection />
                <VisiMisi />
                <MyCarousel />
                <SampleLoker />
                <SampleMagang />
                <SampleSertiikasi />
                <SampleWorkshop />
                <SamplePelatihan />
                <Footer />
            </LayoutLandingPage>
        </>
    )
}

export default LandingPage