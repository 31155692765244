import { Link } from 'react-router-dom'
import Logo from '../../assets/images/Logo.svg'
import LogoGentra from '../../assets/images/LogoGentra.png'
import FormatTime from '../format/time'
import FormatDate from '../format/date'
import CustomCurrency from '../customs/currency'

const CardWorkshop = ({ link, title, lembagaName, lembagaCategory, cost, metoda, implementationDate, createdAt}) => {
    return (
        <div className="w-full drop-shadow-xl shadow-gray-300 shadow-md rounded-xl border-2 hover:border-custom-yellow transition-colors p-3 bg-white text-black">
            <div className="flex items-center justify-between border-b-2 border-black pb-3 mb-3">
                <img src={LogoGentra} alt="Logo Gentra Karya v2" className='h-14 object-contain' />
                <img src={Logo} alt="Logo Gentra Karya v1" className='h-12 object-contain' />
            </div>
            <p className='uppercase text-center text-lg font-semibold'>{title}</p>
            <div className="flex text-sm mt-3">
                <div className="w-32 font-semibold">Nama Instansi</div>
                <div className='px-2 font-semibold'>:</div>
                <div className='uppercase'>{lembagaName}</div>
            </div>

            <div className="flex text-sm">
                <div className="w-32 font-semibold">Nama Lembaga</div>
                <div className='px-2 font-semibold'>:</div>
                <div className='uppercase'>{lembagaCategory}</div>
            </div>

            <div className="flex text-sm">
                <div className="w-32 font-semibold">Biaya Workshop</div>
                <div className='px-2 font-semibold'>:</div>
                <div>
                    <CustomCurrency value={cost} />
                </div>
            </div>

            <div className="flex text-sm">
                <div className="w-32 font-semibold">Metoda Workshop</div>
                <div className='px-2 font-semibold'>:</div>
                <div className='capitalize'>
                    {metoda === 'hybrid' ? 'Offline & Online' : metoda}
                </div>
            </div>

            <div className="flex text-sm mt-3">
                <div className="w-32 font-semibold">Pelaksanaan</div>
                <div className='px-2 font-semibold'>:</div>
                <div>
                    <FormatDate data={implementationDate} />, &nbsp;
                    <FormatTime data={implementationDate} />
                </div>
            </div>


            <div className='flex items-center justify-between pt-5'>
                <div>
                    <div className="flex space-x-3 text-xs">
                        <div className="">Dibuat Pada</div>
                        <div>:</div>
                        <div>
                            <FormatDate data={createdAt} />, &nbsp;
                            <FormatTime data={createdAt} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-sm border-t-2 border-black mt-2 flex items-center justify-between pt-2'>
                <p className='text-end mb-2 uppercase font-semibold'>Workshop</p>
                <Link to={link} className='px-3 py-2 text-sm bg-custom-yellow rounded-md left-0 text-center font-semibold'>
                    Lihat Detail...
                </Link>
            </div>
        </div>
    )
}

export default CardWorkshop