
const FormatTime = ({ data }) => {
    const date = new Date(data);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedTime = `${hours}:${minutes} WIB`;

    return formattedTime;
};

export default FormatTime;
