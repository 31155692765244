const { Link, useHref } = require("react-router-dom")

const SideLink = ({ name, link }) => {
    const currentPath = useHref()
    return (
        <Link to={link} >
            <div className="flex items-center space-x-3 font-semibold py-2 px-3 rounded-full md:rounded-l-full relative capitalize group ">
                <p className="z-10 w-full ">{name}</p>
                <div
                    className={`absolute h-full bg-white right-0 group-hover:w-full transition-all duration-300 rounded-l-full rounded-r-full  lg:rounded-r-none
                    ${link === "/dashboard" && link === currentPath ? "w-full" : link !== '/dashboard' && currentPath.startsWith(link) ? "w-full" : "w-0" }
                    `}
                ></div>
            </div>
        </Link>
    )
}

export default SideLink