import apiService from "./api_service";

export const getProfile = async ( accessToken ) => {
    try {
        const response = await apiService.get(`profile`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const updateProfile = async ( accessToken, data ) => {
    try {
        const response = await apiService.post(`profile/update`, data, {
            headers: {
                Authorization: accessToken,
                 'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const updateProfileImage = async ( accessToken, data ) => {
    try {
        const response = await apiService.post(`profile/update/image`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};
