import apiService from "./api_service";

export const getLayanan = async () => {
    try {
        const response = await apiService.get(`layanan`, {
            headers: {
                // Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const postLayanan = async (accessToken, data) => {
    try {
        const response = await apiService.post(`layanan/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const updateLayanan = async (accessToken, data, id) => {
    try {
        const response = await apiService.post(`layanan/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const deleteLayanan = async (accessToken, id) => {
    try {
        const response = await apiService.post(`layanan/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};