import { useState } from "react";
import LayoutModal from "../../../components/layouts/modal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUpload } from "@fortawesome/free-solid-svg-icons";
import BtnDefault from "../../../components/buttons/default_button";
import { postImageSlider } from "../../../services/image_slider_service";
import CustomSpinner from './../../../components/customs/spinner';

const SlideShowCreate = ({ open, setOpen, accessToken, setRefresh }) => {
    const [loading, setLoading] = useState(false);

    const [images, setImages] = useState([]);
    const [error, setError] = useState(null);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files || []);
        const newImages = files.map(file => ({
            file,
            url: URL.createObjectURL(file)
        }));
        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        URL.revokeObjectURL(images[index].url);
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            setError(null);
            if (images.length === 0) {
                setError("Silahkan upload gambarnya..!");
            } else {
                const formData = new FormData();
                for (const image of images) {
                    formData.append('images[]', image.file);
                }
                const response = await postImageSlider(accessToken, formData);
                if (response.status === 200) {
                    setOpen(false)
                    setRefresh && setRefresh(true);
                    setImages([])
                } else {
                    setError("Gagal mengupload gambar.");
                }
            }
        } catch (error) {
            alert('Gagal menyimpan data..! ');
        }
        setLoading(false);
    }
    return (
        <LayoutModal name="tambah Data SlideShow" showModal={open} >
            <form onSubmit={handleSubmit} className="p-3 md:p-5">
                <div className="flex flex-col items-center justify-center mb-5">
                    <label htmlFor="file-upload" className="cursor-pointer bg-custom-navy text-custom-dark-yellow px-3 py-2 rounded-md flex items-center space-x-2 border-2 hover:border-blue-500 hover:bg-blue-500 hover:text-white font-semibold transition-all duration-300">
                        <FontAwesomeIcon icon={faUpload} />
                        <p>Upload Gambar</p>
                    </label>
                    <input
                        id="file-upload"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleImageChange}
                        className="mr-3 hidden"
                    />
                    <p className="text-sm text-gray-700">{images.length} gambar dipilih</p>
                    {error && <p className="text-red-500 text-sm">{error}</p>}
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-3 p-3">
                    {images.map((image, index) => (
                        <div key={index} className="relative shadow-md border rounded-xl">
                            <img
                                src={image.url}
                                alt={`Preview ${index}`}
                                className="w-full aspect-square object-cover rounded-lg"
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveImage(index)}
                                className="w-7 aspect-square absolute top-1 right-1 bg-red-500 text-white rounded-full"
                            >
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                    ))}
                </div>

                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>
                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default SlideShowCreate