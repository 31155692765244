import { useEffect } from "react"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { getSampleMagang } from "../../../services/magang_service";
import Title from "../../../components/customs/title";
import CardMagang from "../../../components/cards/magang";

const SampleMagang = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const fetchSampleMagang = async () => {
        try {
            const response = await getSampleMagang();
            setData(response.data.data);
        } catch (error) {
            console.log("__")
        }
    };

    useEffect(() => {
        fetchSampleMagang();
    }, []);
    return (
        <>
            <div className="w-full py-10 px-5 lg:px-10 ">
                <div className="flex justify-between">
                    <Title name="Info Magang"></Title>
                    <Link to="/magang" className="text-black bg-custom-yellow px-3 py-2 rounded-md font-semibold">Lihat Semua</Link>
                </div>
                <div className="w-full mt-3 h-1 rounded-full bg-black"></div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-5">
                    {data?.map((item) => (

                        <CardMagang
                            link={`/magang/detail/${item?.id}`}
                            position={item?.position}
                            lembagaName={item?.lembaga?.name}
                            lembagaCategory={item?.lembaga?.category?.name ?? "-"}
                            minimumSalary={item?.minimum_salary}
                            maximumSalary={item?.maximum_salary}
                            quota={item?.quota}
                            createdAt={item?.created_at}
                            expiredAt={item?.expired_at}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}
export default SampleMagang