import apiService from "./api_service"

export const getMagangImage = async (magangId) => {
    try {
        const response = apiService.get(`magang-image?magang_id=${magangId}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}
