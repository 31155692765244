import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from "../../../components/buttons/back_button"
import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons"
import BtnDefault from "../../../components/buttons/default_button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import InputDefault from "../../../components/forms/input_default"
import CustomSpinner from "../../../components/customs/spinner"
import { getDistrict, getProvince, getRegency, getVillage } from "../../../services/location_service"
import SelectFilter from "../../../components/forms/select_filter"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import { postMagang } from "../../../services/magang_service"
import { useNavigate } from "react-router-dom"
import draftToHtml from "draftjs-to-html"
import { getJenjangPendidikan } from "../../../services/jenjang_pendidikan_service"
import SelectDefault from "../../../components/forms/select_default"
import TextAreaDefault from "../../../components/forms/text_area_default"
import { getGender } from "../../../services/gender_service"
import { getMaritalStatus } from "../../../services/marital_status_service"
import CustomAlert from "../../../components/customs/alert"

const MagangCreate = () => {
    const [accessToken, setAccessToken] = useState(null)
    const navigate = useNavigate()

    const [position, setPosition] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [gendeId, setGenderId] = useState("")
    const [minimumSalary, setMinimumSalary] = useState("0")
    const [maximumSalary, setMaximumSalary] = useState("0")
    const [quota, setQuota] = useState(0)
    const [placement, setPlacement] = useState("")
    const [levelOfEducationId, setLevelOfEducaionId] = useState("")
    const [maritalStatusId, setMaritalStatusId] = useState("")


    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");

    const [expiredAt, setExpiredAt] = useState("")


    const [map, setMap] = useState("");
    const [detailAddress, setDetailAddress] = useState("");

    const [errorPosition, setErrorPosition] = useState(null)
    const [errorCategoryId, setErrorCategoryId] = useState(null)
    const [errorGenderId, setErrorGenderId] = useState(null)
    const [errorQuota, setErrorQuota] = useState(null)
    const [errorPlacement, setErrorPlacement] = useState(null)
    const [errorLevelOfEducationId, setErrorLevelOfEducationId] = useState(null)
    const [errorMaritalStatusId, setErrorMaritalStatusId] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)

    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null)
    const [errorEmail, setErrorEmail] = useState(null)

    const [errorExpiredAt, setErrorExpiredAt] = useState(null)

    const [errorProvince, setErrorProvince] = useState(null);
    const [errorRegency, setErrorRegency] = useState(null);
    const [errorDistrict, setErrorDistrict] = useState(null);
    const [errorVillage, setErrorVillage] = useState(null);
    const [errorMap, setErrorMap] = useState(null);
    const [errorDetailAddress, setErrorDetailAddress] = useState(null);

    const [loading, setLoading] = useState(false);

    const [images, setImages] = useState([]);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files || []);
        const newImages = files.map(file => ({
            file,
            url: URL.createObjectURL(file)
        }));
        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleRemoveImage = (index) => {
        setImages(prevImages => prevImages.filter((_, i) => i !== index));
    };


    const [dataMaritalStatus, setDataMaritalStatus] = useState([])
    const fetchDataMaritalStatus = async () => {
        try {
            const response = await getMaritalStatus()
            if (response.status === 200) {
                setDataMaritalStatus(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [dataGender, setDataGender] = useState([])
    const fetchDataGender = async () => {
        try {
            const response = await getGender()
            if (response.status === 200) {
                setDataGender(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [dataJenjangPendidikan, setDataJenjangPendidikan] = useState([])
    const fetchDataJenjangPendidikan = async () => {
        try {
            const response = await getJenjangPendidikan()
            if (response.status === 200) {
                setDataJenjangPendidikan(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [province, setProvince] = useState([]);
    const [provinceId, setProvinceId] = useState(null);
    const fetchDataProvince = async () => {
        try {
            const response = await getProvince()
            if (response.status === 200) {
                setProvince(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [regency, setRegency] = useState([]);
    const [regencyId, setRegencyId] = useState(null);
    const fetchDataRegency = async (provinceId) => {
        try {
            const response = await getRegency(provinceId)
            if (response.status === 200) {
                setRegency(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState(null);
    const fetchDataDistrict = async (regencyId) => {
        try {
            const response = await getDistrict(regencyId)
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const [village, setVillage] = useState([]);
    const [villageId, setVillageId] = useState(null);
    const fetchDataVillage = async (districtId) => {
        try {
            const response = await getVillage(districtId)
            if (response.status === 200) {
                setVillage(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        try {
            const getAccessToken = localStorage.getItem('access_token-gentrakarya')
            setAccessToken(getAccessToken)
            fetchDataProvince()
            fetchDataGender()
            fetchDataMaritalStatus()
            fetchDataJenjangPendidikan()
        } catch (error) {
            alert("Silahkan periksa koneksi internet Anda..!");
        }
        // eslint-disable-next-line
    }, []);

    const handleProvince = (e) => {
        const provinceId = e
        setProvinceId(provinceId);
        fetchDataRegency(provinceId)
        setRegencyId(null)
        setDistrict([]);
        setDistrictId(null);
        setVillage([]);
        setVillageId(null);
    };

    const handleRegency = (e) => {
        const regencyId = e
        setRegencyId(regencyId);
        fetchDataDistrict(regencyId)
        setVillage([]);
        setVillageId(null);
    };

    const handleDistrict = (e) => {
        const districtId = e
        setDistrictId(districtId);
        fetchDataVillage(districtId)
    };

    const handleVillage = (e) => {
        const vilageId = e
        setVillageId(vilageId);
    };

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorPosition(null)

            setErrorProvince(null)
            setErrorRegency(null)
            setErrorDistrict(null)
            setErrorVillage(null)

            if (position.length === 0) {
                setErrorPosition("Posisi / Jabatan tidak boleh kosong..!")
            } else if (provinceId === null) {
                setErrorProvince("Silahkan pilih provinsi terlebih dahulu..!")
            } else if (regencyId === null) {
                setErrorRegency("Silahkan pilih kabupaten/kota terlebih dahulu..!")
            } else if (districtId === null) {
                setErrorDistrict("Silahkan pilih kecamatan terlebih dahulu..!")
            } else if (villageId === null) {
                setErrorVillage("Silahkan pilih desa / kelurahan terlebih dahulu..!")
            } else {
                const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

                const formData = new FormData()

                for (const image of images) {
                    formData.append('images[]', image.file);
                }

                formData.append('position', position)
                formData.append('minimum_salary', Number(minimumSalary))
                formData.append('maximum_salary', Number(maximumSalary))
                formData.append('quota', quota)
                formData.append('placement', placement)
                formData.append('level_of_education_id', levelOfEducationId)
                formData.append('marital_status_id', maritalStatusId)
                formData.append('description', description)

                formData.append('province_id', provinceId);
                formData.append('regency_id', regencyId);
                formData.append('district_id', districtId);
                formData.append('village_id', villageId);
                formData.append('map', map);
                formData.append('detail_address', detailAddress);

                formData.append('phone_number', phoneNumber);
                formData.append('email', email);

                formData.append('expired_at', expiredAt)

                const response = await postMagang(accessToken, formData)
                if (response.status === 201) {
                    navigate('/dashboard/magang')
                } else {
                    setShowAlert(true)
                    setMessage("Gagal menyimpan data..!")
                    setTypeAlert("danger")
                }
            }
        } catch (error) {
            alert('gagal menyimpan data..!')
        }
        setLoading(false)
    }

    const handleChange = (e, setValue) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setValue(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    return (
        <>
            <Helmet>Tambah Magang Baru</Helmet>
            <LayoutDashboard>
                <form onSubmit={handleSubmit}>
                    <CustomHeader>
                        <BtnBack />
                        <p>Tambah Magang Baru</p>
                        <BtnDefault type={"submit"}>
                            {loading ?
                                <CustomSpinner color="bg-white" /> :
                                <>
                                    <FontAwesomeIcon icon={faSave} />
                                    <p className="hidden lg:flex">Simpan</p>
                                </>
                            }
                        </BtnDefault>
                    </CustomHeader>
                    <div className="p-3">
                        <div className="flex flex-col items-center justify-center mb-5">
                            <label htmlFor="file-upload" className="cursor-pointer bg-custom-navy text-custom-dark-yellow px-3 py-2 flex flex-col items-center space-x-2 border-[3px] border-dashed rounded-xl w-full hover:border-blue-500">
                                <div className="flex items-center gap-3 font-semibold">
                                    <FontAwesomeIcon icon={faUpload} />
                                    <p>Upload Gambar</p>
                                </div>
                                <p className="text-sm text-gray-700">{images.length} gambar dipilih</p>
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleImageChange}
                                className="mr-3 hidden"
                            />
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                            {images.map((image, index) => (
                                <div key={index} className="relative border shadow-md rounded-lg overflow-hidden">
                                    <img
                                        src={image.url}
                                        alt={`Preview ${index}`}
                                        className="w-full aspect-square object-cover"
                                    />
                                    <button
                                        type="button"
                                        onClick={() => handleRemoveImage(index)}
                                        className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 px-3">
                        <div className=" flex flex-col gap-3">
                            <InputDefault
                                id="position"
                                placeholder="jabatan / posisi"
                                label="Jabatan / Posisi"
                                value={position} setValue={setPosition}
                                errorMessage={errorPosition}
                            />

                            <InputDefault
                                id="minSalary" label="Minimal Gaji"
                                value={formatCurrency(minimumSalary)}
                                inputMode="numeric"
                                handleChange={(e) => handleChange(e, setMinimumSalary)}

                            />
                            <InputDefault
                                id="maxSalary" label="Maksimal Gaji"
                                value={formatCurrency(maximumSalary)}
                                inputMode="numeric"
                                handleChange={(e) => handleChange(e, setMaximumSalary)}
                            />

                            <InputDefault
                                id="quota" type="number" label="Kuota Lowongan"
                                inputMode="numeric"
                                value={quota} setValue={setQuota} errorMessage={errorQuota}
                            />

                            <InputDefault id="placement" placeholder="wilayah penempatan" label="Wilayah penempatan" value={placement} setValue={setPlacement} errorMessage={errorPlacement} />

                            <SelectDefault
                                name="Jenjang Pendidikan" placeholder="Pilih minimal pendidikan"
                                options={dataJenjangPendidikan.map(item => ({ value: item.id, label: item.name }))}
                                value={levelOfEducationId} onSelect={(e) => setLevelOfEducaionId(e)}
                                errorMessage={errorDistrict}
                                disabled={false}
                                width="w-full"
                            />

                            <SelectDefault
                                name="Jenis kelamin yang dibutuhkan" placeholder="Pilih jenis kelamin"
                                options={dataGender.map(item => ({ value: item.id, label: item.name }))}
                                value={levelOfEducationId} onSelect={(e) => setLevelOfEducaionId(e)}
                                errorMessage={errorDistrict}
                                disabled={false}
                                width="w-full"
                            />

                            <SelectDefault
                                name="Status pernikahan" placeholder="Pilih status pernikahan"
                                options={dataMaritalStatus.map(item => ({ value: item.id, label: item.name }))}
                                value={maritalStatusId} onSelect={(e) => setMaritalStatusId(e)}
                                errorMessage={errorDistrict}
                                disabled={false}
                                width="w-full"
                            />

                            <InputDefault
                                id="phoneNumber" label="phoneNumber"
                                inputMode="tel" placeholder="No telepon"
                                value={phoneNumber} setValue={setPhoneNumber} errorMessage={errorPhoneNumber}
                            />

                            <InputDefault
                                id="email" label="email"
                                inputMode="tel"
                                placeholder="contoh@gmail.com"
                                value={email} setValue={setEmail} errorMessage={errorEmail}
                            />

                            <InputDefault
                                id="expiredAt" label="Tanggal Expire"
                                type="date"
                                value={expiredAt} setValue={setExpiredAt} errorMessage={errorExpiredAt}
                            />

                        </div>

                        <div className=" flex flex-col gap-3">
                            <SelectFilter
                                name="Pilih Provinsi" placeholder="Pilih provinsi"
                                options={province.map(item => ({ value: item.id, label: item.name }))}
                                value={provinceId} onSelect={(e) => handleProvince(e)}
                                errorMessage={errorProvince}
                            />
                            <SelectFilter
                                name="Pilih Kabupaten / Kota" placeholder="Pilih kabupaten / kota"
                                options={regency.map(item => ({ value: item.id, label: item.name }))}
                                value={regencyId} onSelect={(e) => handleRegency(e)}
                                errorMessage={errorRegency}
                            />

                            <SelectFilter
                                name="Pilih Kecamatan" placeholder="Pilih kecamatan"
                                options={district.map(item => ({ value: item.id, label: item.name }))}
                                value={districtId} onSelect={(e) => handleDistrict(e)}
                                errorMessage={errorDistrict}
                            />

                            <SelectFilter
                                name="Pilih Desa / Kelurahan" placeholder="Pilih desa / kelurahan"
                                options={village.map(item => ({ value: item.id, label: item.name }))}
                                value={villageId} onSelect={(e) => handleVillage(e)}
                                errorMessage={errorVillage}
                            />

                            <InputDefault
                                id="map"
                                label="Link Google Map"
                                inputMode="url"
                                value={map} setValue={setMap} errorMessage={errorMap}
                            />

                            <TextAreaDefault id="detailAddress" label="Detail Alamat" value={detailAddress} setValue={setDetailAddress} />
                        </div>
                    </div>

                    <div className="p-3">
                        <div className="border-2 border-black rounded-xl p-3 bg-white">
                            <Editor
                                editorState={editorState}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onEditorStateChange}
                            />
                        </div>
                    </div>
                </form>
            </LayoutDashboard>

            <CustomAlert showAlert={showAlert} setShowAlert={setShowAlert} message={message} type={typeAlert} />
        </>
    )
}

export default MagangCreate