import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnDefault from "../../../components/buttons/default_button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import GaleryCreate from "./create"
import { useEffect, useState } from "react"
import { deleteGalery, getGaleries } from './../../../services/galery_service';
import { BaseURL } from "../../../services/base_url"

const Galery = () => {
    const [accessToken, setAccessToken] = useState("")
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openCreate, setOpenCreate] = useState(false)

    const [data, setData] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const fetchData = async (page) => {
        setLoading(true)
        try {
            const response = await getGaleries(page)
            if (response.status === 200) {
                setData(response.data.data.data)
                setCurrentPage(response.data.data.meta.current_page)
                setLastPage(response.data.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(currentPage)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-gentrakarya')
        setAccessToken(getAccessToken)
        fetchData(currentPage)
    }, [])

    const handleDeleteImage = async (imageId) => {
        try {
            const response = await deleteGalery(accessToken, imageId)
            if (response.status === 200) {
                setCurrentPage(1)
                setRefresh(true)
            }
        } catch (error) {
            alert('gagal hapus data..!')
        }
    }

    return (
        <>
            <Helmet>Galeri - GENTRAKARYA</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>GALERY</p>
                    <BtnDefault handleClick={() => setOpenCreate(true)}>
                        <FontAwesomeIcon icon={faPlus} />
                        <p>Tambah Foto</p>
                    </BtnDefault>
                </CustomHeader>

                <div className="grid grid-cols-2 md:grid-cols-3, lg:grid-cols-4 gap-3 p-3">
                    {data?.map((item) => (
                        <div className="relative group">
                            <img
                                src={BaseURL + "storage/" + item.image}
                                alt="sdsadsd"
                                className="bg-white border-2 shadow-md w-full aspect-video rounded-xl transition-all duration-700 object-cover"
                            />
                            <button
                                type="button"
                                onClick={(e) => handleDeleteImage(item.id)}
                                className="absolute bottom-0 bg-red-500 w-full h-0 text-white group-hover:h-12 transition-all duration-300 font-semibold"
                            >
                                Hapus
                            </button>
                        </div>
                    ))}
                </div>

            </LayoutDashboard>
            {/* Modal */}
            <GaleryCreate open={openCreate} setOpen={setOpenCreate} accessToken={accessToken} setRefresh={setRefresh} />
        </>
    )
}

export default Galery