
import { useEffect, useState } from "react"
import { getImageslider } from "../../../services/image_slider_service"
import { BaseURL } from "../../../services/base_url"
import { Carousel } from "flowbite-react"

const MyCarousel = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await getImageslider()
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {data.length > 0 && (
                <div className="w-full bg-gray-600 flex items-center justify-center">
                    <div className="w-full aspect-video h-[500px]">
                        <Carousel>
                            {data.map((item) => (
                                <img src={BaseURL + "storage/" + item.image} alt="Image Slider GENTRAKARYA" className="w-full aspect-video object-contain" />
                            ))}
                        </Carousel>
                    </div>
                </div>
            )}
        </>
    )
}

export default MyCarousel