import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import BtnBack from "../../../components/buttons/back_button";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { showMagang } from "../../../services/magang_service";
import CardMagangDetail from "../../../components/cards/magang_detail";

const MagangDetailLp = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [registrationCategory, setRegistrationCategory] = useState(null)
    const navigate = useNavigate()
    const { id } = useParams()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState(null)
    const fetchData = async (id) => {
        setLoading(true)
        try {
            const response = await showMagang(id);
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        try {
            const getAccessToken = localStorage.getItem('access_token-gentrakarya')
            const getRegistrasionCategory = localStorage.getItem('registration_category-gentrakarya')

            setAccessToken(getAccessToken)
            setRegistrationCategory(getRegistrasionCategory)

            fetchData(id)
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
    }, [])
    return (
        <div className="w-full h-screen overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-blue-500">
            <div className="sticky top-0 z-10 h-14 flex items-center justify-between px-3 md:px-5 lg:px-10 text-sm border-b-2 bg-blue-300 space-x-3">
                <BtnBack />
                <div className="flex items-center space-x-3">
                    <FontAwesomeIcon
                        icon={faBell}
                        className="h-8 aspect-square text-black"
                    />
                    <p className="text-base md:text-2xl font-semibold">
                        Detail Magang - Gantra Karya
                    </p>
                </div>
            </div>

            <CardMagangDetail magang_id={id} category={"--"} />
        </div>
    )
}

    export default MagangDetailLp