import apiService from "./api_service";

export const getCertification = async (accessToken, category, provinceId, regencyId, districtId, villageId, keyword, page) => {
    try {
        const response = await apiService.get(`certification`, {
            params: {
                category: category,
                province_id: provinceId,
                regency_id: regencyId,
                district_id: districtId,
                village_id: villageId,
                keyword: keyword,
                page: page
            },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const getCertificationSample = async () => {
    try {
        const response = apiService.get('certification/sample', {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}

export const showCertification = async (id) => {
    try {
        const response = await apiService.get(`certification/show/${id}`, {
            headers: {
              'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const postCertification = async (accessToken, data ) => {
    try {
        const response = await apiService.post(`certification/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const deleteCertification = async (accessToken, id) => {
    try {
        const response = await apiService.post(`certification/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    }
    catch (error) {
        return error.response
    }
};
