import { useEffect } from "react";
import { useState } from "react";
import { getSampleTraining } from "../../../services/training_service";
import CardTraining from "../../../components/cards/training";
import Title from "../../../components/customs/title";
import { Link } from "react-router-dom";

const SamplePelatihan = () => {
    const [data, setData] = useState([]);
    const fetchSamplePelatihan = async () => {
        try {
            const response = await getSampleTraining();
            setData(response.data.data);
        } catch (error) {
            console.log("__")
        }
    };

    useEffect(() => {
        fetchSamplePelatihan();
    }, []);
    return (
        <>
            <div className="w-full py-10 px-5 lg:px-10 ">
                <div className="flex justify-between">
                    <Title name="Info Pelatihan"></Title>
                    <Link to="/pelatihan" className="text-black bg-custom-yellow px-3 py-2 rounded-md font-semibold">Lihat Semua</Link>
                </div>
                <div className="w-full mt-3 h-1 rounded-full bg-black"></div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 py-5">
                    {data?.map((item) => (
                        <CardTraining
                            link={`/pelatihan/detail/${item?.id}`}
                            title={item?.title}
                            lembagaName={item?.lembaga?.name}
                            lembagaCategory={item?.lembaga?.category?.name ?? "-"}
                            cost={item?.training_cost}
                            metoda={item?.metoda?.name}
                            implementationDate={item?.implementation_date}
                            createdAt={item?.created_at}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}
export default SamplePelatihan