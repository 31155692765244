import { faCircleCheck, faCircleXmark } from "@fortawesome/free-regular-svg-icons"
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"

const CustomAlert = ({ showAlert, setShowAlert, message, type }) => {
    useEffect(() => {
        setTimeout(() => {
            setShowAlert(false)
        }, 5000)
    }, [showAlert])
    return (
        <div className={`fixed top-0 left-0 px-3 z-[10000] py-5 w-full flex items-center justify-center ${showAlert ? 'block' : 'hidden'}`}>
            <div className={`h-16 w-full  rounded-xl flex items-center justify-center space-x-3 backdrop-blur-sm ${type === 'success' ? 'bg-green-500/70' : type === 'danger' ? 'bg-red-500/70' : type === 'warning' ? 'bg-yellow-500/70' : null}`}>
                {type === 'success' ? (
                    <FontAwesomeIcon icon={faCircleCheck} className="text-lime-500 text-3xl" />
                ) : type === 'danger' ? (
                    <FontAwesomeIcon icon={faCircleXmark} className="text-red-500 text-3xl" />
                ) : type === 'warning' ? (
                    <FontAwesomeIcon icon={faTriangleExclamation} className="text-yellow-500 text-3xl" />
                ) : null}
                <p className="text-white font-semibold">{message}</p>
            </div>
        </div>
    )
}

export default CustomAlert