import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/dashboard"
import CustomHeader from "../../../../components/customs/header"
import CardMagang from "../../../../components/cards/magang"

const LamaranMagang = () => {
    return (
        <>
            <Helmet>Lamaran Magang</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Data Lamaran Magang</p>
                </CustomHeader>

                
            </LayoutDashboard>
        </>
    )
}

export default LamaranMagang