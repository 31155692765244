
import { useState } from 'react';
import InputDefault from '../../../components/forms/input_default';
import LayoutModal from '../../../components/layouts/modal';
import BtnDefault from '../../../components/buttons/default_button';
import CustomSpinner from '../../../components/customs/spinner';
import { updateLembagaAddress } from '../../../services/profile_lembaga_service';
import SelectFilter from '../../../components/forms/select_filter';
import { getDistrict, getProvince, getRegency, getVillage } from '../../../services/location_service';
import { useEffect } from 'react';
import TextAreaDefault from '../../../components/forms/text_area_default';
const UpdateLembagaAddress = (
    {
        accessToken,
        open, setOpen,
        getProvinceId, getRegencyId, getDistrictId, getVillageId, getMap, getDetail,
        setAlert, setAlertMessage, setAlertType,

        setRefresh
    }
) => {
    const [loading, setLoading] = useState(false)

    const [provinceId, setProvinceId] = useState("")
    const [province, setProvince] = useState([])

    const [regencyId, setRegencyId] = useState("")
    const [regency, setRegency] = useState([])

    const [districtId, setDistrictId] = useState("")
    const [district, setDistrict] = useState([])

    const [villageId, setVillageId] = useState("")
    const [village, setVillage] = useState([])

    const [map, setMap] = useState("")
    const [detailAddress, setDetailAddress] = useState("")

    const [errorProvince, setErrorProvince] = useState(null)
    const [errorRegency, setErrorRegency] = useState(null)
    const [errorDistrict, setErrorDistrict] = useState(null)
    const [errorVillage, setErrorVillage] = useState(null)
    const [errorMap, setErrorMap] = useState(null)
    const [errorDetailAddress, setErrorDetailAddress] = useState(null)

    const fetchDataProvince = async () => {
        try {
            const response = await getProvince()
            if (response.status === 200) {
                setProvince(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const fetchDataRegency = async (provinceId) => {
        try {
            const response = await getRegency(provinceId)
            if (response.status === 200) {
                setRegency(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const fetchDataDistrict = async (regencyId) => {
        try {
            const response = await getDistrict(regencyId)
            if (response.status === 200) {
                setDistrict(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    const fetchDataVillage = async (districtId) => {
        try {
            const response = await getVillage(districtId)
            if (response.status === 200) {
                setVillage(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
    }

    useEffect(() => {
        setProvinceId(getProvinceId)
        setRegencyId(getRegencyId)
        setDistrictId(getDistrictId)
        setVillageId(getVillageId)
        setMap(getMap)
        setDetailAddress(getDetail)

        fetchDataProvince()
        fetchDataRegency(getProvinceId)
        fetchDataDistrict(getRegencyId)
        fetchDataVillage(getDistrictId)

    }, [getProvinceId, getRegencyId, getDistrictId, getVillageId, getMap, getDetail])


    const handleProvince = (e) => {
        const provinceId = e
        setProvinceId(provinceId);
        fetchDataRegency(provinceId)
        setRegencyId(null)
        setDistrict([]);
        setDistrictId(null);
        setVillage([]);
        setVillageId(null);
    };

    const handleRegency = (e) => {
        const regencyId = e
        setRegencyId(regencyId);
        fetchDataDistrict(regencyId)
        setVillage([]);
        setVillageId(null);
    };

    const handleDistrict = (e) => {
        const districtId = e
        setDistrictId(districtId);
        fetchDataVillage(districtId)
    };

    const handleVillage = (e) => {
        const vilageId = e
        setVillageId(vilageId);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorProvince(null)
            setErrorRegency(null)
            setErrorDistrict(null)
            setErrorVillage(null)
            setErrorMap(null)
            setErrorDetailAddress(null)

            if (!provinceId) {
                setErrorProvince("Provinsi tidak boleh kosong")
            } else if (!regencyId) {
                setErrorRegency("Kabupaten tidak boleh kosong")
            } else if (!districtId) {
                setErrorDistrict("Kecamatan tidak boleh kosong")
            } else if (!villageId) {
                setErrorVillage("Kelurahan tidak boleh kosong")
            } else if (!map) {
                setErrorMap("Map tidak boleh kosong")
            } else if (!detailAddress) {
                setErrorDetailAddress("Detail tidak boleh kosong")
            } else {

                const formData = new FormData()
                formData.append("province_id", provinceId)
                formData.append("regency_id", regencyId)
                formData.append("district_id", districtId)
                formData.append("village_id", villageId)
                formData.append("map", map)
                formData.append("detail_address", detailAddress)

                const response = await updateLembagaAddress(accessToken, formData)
                if (response.status === 200) {
                    setAlert(true)
                    setAlertMessage(response.data.message)
                    setAlertType("success")
                    setOpen(false)
                    setRefresh(true)
                } else {
                    setAlert(true)
                    setAlertMessage(response.data.message)
                    setAlertType("danger")
                    setOpen(false)
                }
            }
        } catch (error) {
            setAlert(true)
            setAlertMessage(error.message)
            setAlertType("danger")
            setOpen(false)
        }

        setLoading(false)
    }

    return (
        <LayoutModal name="Update Alamat Lembaga" showModal={open} addClass="md:w-[700px] h-[570px]">
            <form onSubmit={handleSubmit} className='px-3 flex flex-col gap-3'>
                <SelectFilter
                    name="Pilih Provinsi" placeholder="Pilih provinsi"
                    options={province.map(item => ({ value: item.id, label: item.name }))}
                    value={provinceId} onSelect={(e) => handleProvince(e)}
                    errorMessage={errorProvince}

                />
                <SelectFilter
                    name="Pilih Kabupaten / Kota" placeholder="Pilih kabupaten / kota"
                    options={regency.map(item => ({ value: item.id, label: item.name }))}
                    value={regencyId} onSelect={(e) => handleRegency(e)}
                    errorMessage={errorRegency}
                />

                <SelectFilter
                    name="Pilih Kecamatan" placeholder="Pilih kecamatan"
                    options={district.map(item => ({ value: item.id, label: item.name }))}
                    value={districtId} onSelect={(e) => handleDistrict(e)}
                    errorMessage={errorDistrict}
                />

                <SelectFilter
                    name="Pilih Desa / Kelurahan" placeholder="Pilih desa / kelurahan"
                    options={village.map(item => ({ value: item.id, label: item.name }))}
                    value={villageId} onSelect={(e) => handleVillage(e)}
                    errorMessage={errorVillage}
                />

                <InputDefault id="map" label="Link Google Map" value={map} setValue={setMap} errorMessage={errorMap} />

                <TextAreaDefault id="detailAddress" label="Detail Alamat" value={detailAddress} setValue={setDetailAddress} />

                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>
                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UpdateLembagaAddress