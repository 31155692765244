    import { Link } from 'react-router-dom'
import Logo from '../../assets/images/Logo.svg'
import LogoGentra from '../../assets/images/LogoGentra.png'
import FormatDate from '../format/date'
import FormatDatetime from '../format/time'

const CardMagang = ({ link, position, lembagaName, lembagaCategory, minimumSalary, maximumSalary, createdAt, expiredAt, quota }) => {
    return (
        <div className="w-full drop-shadow-xl shadow-gray-300 shadow-md rounded-xl border-2 hover:border-custom-yellow transition-colors p-3 bg-white text-black">
            <div className="flex items-center justify-between border-b-2 border-black pb-3 mb-3">
                <img src={LogoGentra} alt="Logo Gentra Karya v2" className='h-14 object-contain' />
                <img src={Logo} alt="Logo Gentra Karya v1" className='h-12 object-contain' />
            </div>
            <p className='uppercase text-center text-lg font-semibold'>{position}</p>
            <table className=''>
                <tr>
                    <td className='font-semibold capitalize'>Nama Perusahaan</td>
                    <td className='px-2'>:</td>
                    <td className='uppercase'>{lembagaName}</td>
                </tr>
                <tr>
                    <td className='font-semibold capitalize'>Kategori Lembaga</td>
                    <td className='px-2'>:</td>
                    <td className='uppercase'>{lembagaCategory}</td>
                </tr>
                <tr>
                    <td className='font-semibold capitalize'>Rentang Gaji</td>
                    <td className='px-2'>:</td>
                    <td className='uppercase'>
                    <span>{minimumSalary}</span>
                    {minimumSalary === 0 || maximumSalary === 0 ? '' :
                        <>
                            <span className='px-2'>-</span>
                            <span>Rp. {maximumSalary}</span>
                        </>
                    }
                    </td>
                </tr>
                <tr>
                    <td className='font-semibold capitalize'>Kuota Lowongan</td>
                    <td className='px-2'>:</td>
                    <td className='uppercase'>{quota} Orang</td>
                </tr>
            </table>


            <div className='flex items-center justify-between pt-5'>
                <div>
                    <div className="flex space-x-3 text-xs">
                        <div className="">Dibuat Pada</div>
                        <div>:</div>
                        <div>
                            <FormatDate data={createdAt} />, &nbsp;
                            <FormatDatetime data={createdAt} />
                        </div>
                    </div>
                    <div className="flex space-x-3 text-xs">
                        <div className="">Berakhir Pada</div>
                        <div>:</div>
                        <div><FormatDate data={expiredAt}></FormatDate></div>
                    </div>
                </div>
            </div>
            <div className='text-sm border-t-2 border-black mt-2 flex items-center justify-between pt-2'>
                <p className='text-end mb-2 uppercase font-semibold'>Magang</p>
                <Link to={link} className='px-3 py-2 text-sm bg-custom-yellow rounded-md left-0 text-center font-semibold'>
                    Lihat Detail...
                </Link>
            </div>
        </div>
    )
}

export default CardMagang