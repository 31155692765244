import Sidebar from "../navigations/sidebar"

const LayoutDashboard = ({ children }) => {
    return (
        <div className="w-full h-screen flex bg-white text-black">
            <Sidebar />
            <div className="w-full h-screen overflow-auto z-10 scrollbar-thin scrollbar-track-custom-blue scrollbar-thumb-custom-yellow">
                {children}
            </div>
        </div>
    )
}

export default LayoutDashboard