import apiService from "./api_service";

export const getMagang = async (accessToken, category, provinceId, regencyId, districtId, villageId, keyword, page) => {
    try {
        const response = await apiService.get(`magang`, {
            params: {
                category: category,
                province_id: provinceId,
                regency_id: regencyId,
                district_id: districtId,
                village_id: villageId,
                keyword: keyword,
                page: page
            },  
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const getSampleMagang = async () => {
    try {
        const response = apiService.get('magang/sample', {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
    }
}

export const showMagang = async (magangId) => {
    try {
        const response = apiService.get(`magang/show/${encodeURIComponent(magangId)}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response
        console.error('Error fetching data:', error);
    }
}

export const postMagang = async (accessToken, data) => {
    try {
        const response = await apiService.post(`magang/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const deleteMagang = async (accessToken, id) => {
    try {
        const response = await apiService.post(`magang/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    }
    catch (error) {
        return error.response
    }
};