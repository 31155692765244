import apiService from "./api_service";

export const getUser = async ( accessToken, keyword, page, categoryId, subCategoryId ) => {
    try {
        const response = await apiService.get(`pengguna?page=${page}&keyword=${keyword}&category_id=${categoryId}&sub_category_id=${subCategoryId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};