import { ContentState, convertToRaw, EditorState, convertFromHTML } from "draft-js";
import LayoutModal from "../../../components/layouts/modal"
import { Editor } from "react-draft-wysiwyg"
import { useEffect, useState } from "react";
import BtnDefault from "../../../components/buttons/default_button";
import CustomSpinner from "../../../components/customs/spinner";
import { postLayanan, updateLayanan } from "../../../services/layanan_service";
import draftToHtml from "draftjs-to-html";
import InputDefault from "../../../components/forms/input_default";


const LayananCreate = ({ accesToken, open, setOpen, setRefresh, getId, getName, getDetail }) => {
    const [loading, setLoading] = useState(false)

    const [layananId, setLayananId] = useState("")
    const [name, setName] = useState("")
    const [errorName, setErrorName] = useState(null)

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const [focusEditor, setFocusEditor] = useState(false)

    useEffect(() => {
        if (getId) {
            setLayananId(getId)
        }

        if (getName) {
            setName(getName)
        }

        if (getDetail) {
            const contentState = convertFromHTML(getDetail);
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(contentState.contentBlocks)));
        }
    }, [getId, getName, getDetail])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorName(null)
            if (name.length === 0) {
                setErrorName("Nama layanan tidak boleh kosong..!")
            } else if (name.length < 3) {
                setErrorName('Nama layanan minimal terdiri dari 3 huruf..!')
            } else {
                const description = draftToHtml(convertToRaw(editorState.getCurrentContent()));

                const formData = new FormData()
                formData.append('name', name)
                formData.append('detail', description)

                if (getId) {
                    const response = await updateLayanan(accesToken, formData, layananId)
                    if (response.status === 200) {
                        setOpen(false)
                        setRefresh(true)
                        setName("")
                        setLayananId("")
                    } else {
                        alert('Gagal menyimpan data..!')
                    }
                } else {
                    const response = await postLayanan(accesToken, formData)
                    if (response.status === 201) {
                        setOpen(false)
                        setRefresh(true)
                        setName("")
                        setLayananId("")
                    } else {
                        alert('Gagal menyimpan data..!')
                    }
                }
            }
        } catch (error) {
            alert('Gagal menyimpan data..!')
        }
        setLoading(false)
    }



    return (
        <LayoutModal showModal={open} name={`${getName ? "Update layanan" : "Tambah layanan baru"} `}>
            <form onSubmit={handleSubmit} className="px-3 flex flex-col gap-3">
                <InputDefault id="name" label="Nama Layanan" value={name} setValue={setName} placeholder="Masukan nama layanan" errorMessage={errorName} />
                <div className={`border-2 ${focusEditor ? "border-blue-500 outline-blue-500" : "border-black"} rounded-xl p-3 bg-white text-black`}>
                    <Editor
                        onFocus={() => setFocusEditor(true)}
                        onBlur={() => setFocusEditor(false)} // Menambahkan onBlur untuk mengatur fokus menjadi false
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={onEditorStateChange}
                    />
                </div>
                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>

                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default LayananCreate