import Navbar from "../navigations/navbar"

const LayoutLandingPage = ({ children }) => {
    return (
        <div className="w-full h-screen overflow-auto bg-white text-black scrollbar-thin scrollbar-track-custom-blue scrollbar-thumb-custom-yellow">
            <Navbar />
            {children}
        </div>
    )
}

export default LayoutLandingPage