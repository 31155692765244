
import { useState, useEffect } from 'react';
import InputDefault from '../../../components/forms/input_default';
import LayoutModal from './../../../components/layouts/modal';
import BtnDefault from '../../../components/buttons/default_button';
import CustomSpinner from '../../../components/customs/spinner';
import { updateLembaga } from '../../../services/profile_lembaga_service';
import TextAreaDefault from './../../../components/forms/text_area_default';
const UpdateProfileLembaga = (
    {
        accessToken,
        open, setOpen,
        getName, getResponsibleName, getDescription,

        setAlert, setAlertMessage, setAlertType,

        setRefresh
    }
) => {
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("")
    const [responsibleName, setResponsibleName] = useState("")
    const [description, setDescription] = useState("")

    const [errorName, setErrorName] = useState(null)
    const [errorResponsibleName, setErrorResponsibleName] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)

    useEffect(() => {
        setName(getName)
        setResponsibleName(getResponsibleName)
        setDescription(getDescription)
    }, [getName, getResponsibleName, getDescription])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorName(null)
            setErrorResponsibleName(null)
            setErrorDescription(null)

            if (!name) {
                setErrorName("Nama Lembaga tidak boleh kosong..!")
            } else if (!responsibleName) {
                setErrorResponsibleName("Nama Penanggung Jawab tidak boleh kosong..!")
            } else if (!description) {
                setErrorDescription("Deskripsi Lembaga tidak boleh kosong..!")
            } else {
                const formData = new FormData()
                formData.append("name", name)
                formData.append("responsible_name", responsibleName)
                formData.append("description", description)

                const response = await updateLembaga(accessToken, formData)
                if (response.status === 200) {
                    setAlert(true)
                    setAlertMessage("Berhasil mengupdate data lembaga..!")
                    setAlertType("success")
                    setOpen(false)
                    setName("")
                    setResponsibleName("")
                    setDescription("")
                    setRefresh(true)
                } else {
                    setAlert(true)
                    setAlertMessage("Gagal mengupdate data lembaga..!")
                    setAlertType("danger")
                    setOpen(false)
                }
            }
        } catch (error) {
            console.log(error)
            setAlert(true)
            setAlertMessage("Gagal mengupdate data lembaga..!")
            setAlertType("danger")
        }
        setLoading(false)
    }
    return (
        <LayoutModal name="Update Profil Lembaga" showModal={open} addClass="md:w-[700px] h-[490px]">
            <form onSubmit={handleSubmit} className='px-3 flex flex-col gap-3'>
                <InputDefault id="name" label="Nama Lembaga" value={name} setValue={setName} errorMessage={errorName} />
                <InputDefault id="responsibleName" label="Nama Penanggung Jawab" value={responsibleName} setValue={setResponsibleName} errorMessage={errorResponsibleName} />
                <TextAreaDefault cols={10} rows={7}
                    id="description" label="Deskripsi Lembaga / Perusahaan"
                    value={description} setValue={setDescription}
                    errorMessage={errorDescription}
                />

                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>
                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UpdateProfileLembaga