import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { deleteLayanan, getLayanan } from "../../../services/layanan_service"
import LayananCreate from "./create"
import BtnDefault from "../../../components/buttons/default_button"

const Layanan = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openCreate, setOpenCreate] = useState(false)

    const [data, setData] = useState([])
    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await getLayanan()
            if (response.status === 200) {
                setData(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData()
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem("access_token-gentrakarya")
        setAccessToken(getAccessToken)
        fetchData()
    }, [])

    const handleDelete = async (layananId) => {
        try {
            const response = await deleteLayanan(accessToken, layananId)
            if (response.status === 200) {
                setRefresh(true)
            } else {
                alert('Gagal menghapus data..!')
            }
        } catch (error) {
            alert('Gagal menghapus data..!')
        }
    }

    const [editId, setEditId] = useState("")
    const [editName, setEditName] = useState("")
    const [editDetail, setEditDetail] = useState("")

    return (
        <>
            <Helmet>Layanan GENTRAKARYA</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Layanan GENTRAKARYA</p>
                    <BtnDefault handleClick={() => {
                        setEditId("")
                        setEditName("")
                        setEditDetail("")
                        setOpenCreate(true)
                    }}>
                        <FontAwesomeIcon icon={faPlus} />
                        <p className="hidden lg:flex">Tambah Layanan</p>
                    </BtnDefault>
                </CustomHeader>

                <div className="accordion-group accordion-group-secondary p-3 text-sm lg:text-base ">
                    {data?.map((item, index) => (
                        <div className="accordion">
                            <input type="checkbox" id={`toggle-${index}`} className="accordion-toggle" />
                            <label htmlFor={`toggle-${index}`} className=" py-0  ">
                                <div className="flex flex-row items-center justify-between bg-gray-50 px-3 rounded-lg cursor-pointer">
                                    <p className="uppercase text-black" >{item?.name}</p>

                                    <div className="flex items-center space-x-3 font-semibold text-sm lg:text-base">
                                        <button onClick={() => {
                                            setEditId(item.id)
                                            setEditName(item.name)
                                            setEditDetail(item.detail)
                                            setOpenCreate(true)
                                        }}
                                            className="btn bg-yellow-300 hover:bg-yellow-400 text-white text-sm lg:text-base">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>

                                        <div className="dropdown">
                                            <label className="btn btn-solid-error my-2 text-white bg-red-500 hover:bg-red-600" tabIndex="0">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </label>
                                            <div className="dropdown-menu w-72 z-50 bg-gray-50 text-black">
                                                <div className="p-5 flex flex-col items-center justify-center space-y-3 z-50">
                                                    <svg width="60" height="60" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM24 26C22.9 26 22 25.1 22 24V16C22 14.9 22.9 14 24 14C25.1 14 26 14.9 26 16V24C26 25.1 25.1 26 24 26ZM26 34H22V30H26V34Z" fill="#E92C2C" />
                                                    </svg>
                                                    <p className="text-center">Apakah Anda yakin akan menghapus data layanan ini..?</p>
                                                    <div className="flex items-center space-x-3 text-sm text-white">
                                                        <label className="btn btn-success w-32">
                                                            Tidak
                                                        </label>

                                                        <label onClick={() => handleDelete(item?.id)} className="btn btn-error w-32">
                                                            Ya
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
                            <div className="accordion-content text-content2">
                                <div className="min-h-0 pl-10 pr-5 text-black">
                                    <div dangerouslySetInnerHTML={{ __html: item?.detail }} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </LayoutDashboard>

            {/* Modal */}
            <LayananCreate accesToken={accessToken}
                open={openCreate} setOpen={setOpenCreate}
                setRefresh={setRefresh}
                getId={editId}
                getName={editName} getDetail={editDetail} />
        </>
    )
}

export default Layanan