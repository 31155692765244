import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from "../../../components/buttons/back_button"
import { useNavigate, useParams } from "react-router-dom"
import CardLokerDetail from "../../../components/cards/loker_detail"
import BtnDefault from "../../../components/buttons/default_button"
import CustomSpinner from "../../../components/customs/spinner"
import { useEffect } from "react"
import { useState } from "react"
import { deleteLoker } from "../../../services/loker_service"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
const LokerDetail = () => {
    const { loker_id } = useParams()
    const [accessToken, setAccessToken] = useState(null)
    const [registrationCategory, setRegistrationCategory] = useState(null)
    const navigate = useNavigate()

    
    useEffect(() => {
        try {
            const getAccessToken = localStorage.getItem('access_token-gentrakarya')
            const getRegistrasionCategory = localStorage.getItem('registration_category-gentrakarya')

            setAccessToken(getAccessToken)
            setRegistrationCategory(getRegistrasionCategory)

        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
    }, [])


    const [loadingDelete, setLoadingDelete] = useState(false)
    const handleDelete = async () => {
        setLoadingDelete(true)
        try {
            const response = await deleteLoker(accessToken, loker_id)
            if (response.status === 200) {
                navigate('/dashboard/loker')
            } else {
                alert("Gagal menghapus data..!")
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingDelete(false)
    }

    return (
        <>
            <Helmet>Detail Loker</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p>Detail Loker</p>
                    <BtnDefault handleClick={handleDelete} addClass="bg-red-500 hover:bg-red-600">
                        {loadingDelete ? <CustomSpinner color="bg-white" /> : <>
                            <FontAwesomeIcon icon={faTrash} />
                            <p className="hidden lg:flex">Hapus Loker</p>
                        </>}
                    </BtnDefault>
                </CustomHeader>

                <CardLokerDetail loker_id={loker_id} category={"--"} />
            </LayoutDashboard>
        </>
    )
}

export default LokerDetail