import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const CustomPaginate = ({ currentPage, lastPage, handlePaginate }) => {
    return (
        <div className="flex items-center gap-3 font-semibold text-base md:text-xl">
            <button
                onClick={() => {
                    if (currentPage > 1) {
                        handlePaginate(currentPage - 1)
                    }
                }}
                className="hover:bg-blue-500 bg-blue-100 text-black hover:text-white rounded-xl w-8 aspect-square transition-colors">
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            {currentPage > 2 &&
                <button
                    onClick={() => {
                        if (currentPage > 2) {
                            handlePaginate(currentPage - 2)
                        }
                    }}
                    className="hover:bg-blue-500 bg-blue-100 text-black hover:text-white rounded-xl w-8  aspect-square transition-colors">
                    {currentPage - 2}
                </button>
            }
            {currentPage > 1 &&
                <button
                    onClick={() => {
                        if (currentPage > 1) {
                            handlePaginate(currentPage - 1)
                        }
                    }}
                    className="hover:bg-blue-500 bg-blue-100 text-black hover:text-white rounded-xl w-8  aspect-square transition-colors">
                    {currentPage - 1}
                </button>
            }

            <div className="bg-blue-500 text-white rounded-xl w-8  aspect-square transition-colors flex items-center justify-center">
                {currentPage}
            </div>

            {currentPage < lastPage &&
                <button
                    onClick={() => {
                        if (currentPage < lastPage) {
                            handlePaginate(currentPage + 1)
                        }
                    }}
                    className="hover:bg-blue-500 bg-blue-100 text-black hover:text-white rounded-xl w-8  aspect-square transition-colors">
                    {currentPage + 1}
                </button>
            }
            {currentPage < (lastPage - 1) &&
                <button
                    onClick={() => {
                        if (currentPage < (lastPage - 2)) {
                            handlePaginate(currentPage + 1)
                        }
                    }}
                    className="hover:bg-blue-500 bg-blue-100 text-black hover:text-white rounded-xl w-8  aspect-square transition-colors">
                    {currentPage + 2}
                </button>
            }
            <button
                onClick={() => {
                    if (currentPage < lastPage) {
                        handlePaginate(currentPage + 1)
                    }
                }}
                className="hover:bg-blue-500 bg-blue-100 text-black hover:text-white rounded-xl w-8  aspect-square transition-colors">
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
    )
}

export default CustomPaginate