import { useEffect } from "react";

const HandleClickOutside = ({ setOpen, filterRef }) => {
    function handleClickOutside(event) {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [filterRef])

    return null
}

export default HandleClickOutside