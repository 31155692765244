import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LandingPage from './pages/landing_page';
import Dashboard from './pages/dashboard';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import EmailConfirmation from './pages/auth/email_confirmation';
import ForgotPassword from './pages/auth/forgot_password';
import CreateNewPassword from './pages/auth/create_new_password';
import Loker from './pages/dashboard/loker';

import Admin from './pages/dashboard/admin';
import SlideShow from './pages/dashboard/slideshow';
import LokerCreate from './pages/dashboard/loker/create';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import Pengguna from './pages/dashboard/pengguna';
import LokerDetail from './pages/dashboard/loker/detail';
import Layanan from './pages/dashboard/layanan';
import Galery from './pages/dashboard/galery';
import LamaranKerja from './pages/dashboard/loker/lamaran';
import Magang from './pages/dashboard/magang';
import Sertifikasi from './pages/dashboard/sertifikasi';
import Workshop from './pages/dashboard/workshop';
import LamaranMagang from './pages/dashboard/magang/lamaran';
import Pengaturan from './pages/dashboard/pengaturan';
import MagangLp from './pages/landing_page/pages/magang';
import SertifikasiLp from './pages/landing_page/pages/sertifikasi';
import WorkshopLp from './pages/landing_page/pages/workshop';
import PelatihanLp from './pages/landing_page/pages/pelatihan';
import LayananLp from './pages/landing_page/pages/layanan';
import CreateLembaga from './pages/dashboard/pengaturan/create_lembaga';
import TrainingCreate from './pages/dashboard/training/create';
import Training from './pages/dashboard/training';
import TrainingDetail from './pages/dashboard/training/detail';
import WorkshopCreate from './pages/dashboard/workshop/create';
import Certification from './pages/dashboard/certification';
import CertificationCreate from './pages/dashboard/certification/create';
import CertificationDetail from './pages/dashboard/certification/detail';
import WorkshopDetail from './pages/dashboard/workshop/detail';
import WorkshopDetailLp from './pages/landing_page/pages/workshop_detail';
import SertifikasiDetailLp from './pages/landing_page/pages/sertifikasi_detail';
import PelatihanDetailLp from './pages/landing_page/pages/pelatihan_detail';
import MagangCreate from './pages/dashboard/magang/create';
import MagangDetail from './pages/dashboard/magang/detail';
import MagangDetailLp from './pages/landing_page/pages/magang_detail';
import LokerLp from './pages/landing_page/pages/loker';
import LokerDetailLp from './pages/landing_page/pages/loker/loker_detail';
import LokerLamaran from './pages/landing_page/pages/loker/lamaran';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path='/login' Component={Login}></Route>
      <Route path='/register' Component={Register}></Route>
      <Route path="/email-confirmation" Component={EmailConfirmation}></Route>
      <Route path='/forgot-password' Component={ForgotPassword}></Route>
      <Route path="/create-new-password" Component={CreateNewPassword}></Route>

      <Route path='/' Component={LandingPage} />
      <Route path='/loker' Component={LokerLp} />
      <Route path='/loker/detail/:id' Component={LokerDetailLp} />
      <Route path='/loker/lamaran' Component={LokerLamaran} />
      <Route path='/magang' Component={MagangLp} />
      <Route path='/magang/detail/:id' Component={MagangDetailLp} />
      <Route path='/sertifikasi' Component={SertifikasiLp} />
      <Route path='/sertifikasi/detail/:id' Component={SertifikasiDetailLp} />
      <Route path='/workshop' Component={WorkshopLp} />
      <Route path='/workshop/detail/:id' Component={WorkshopDetailLp} />

      <Route path='/pelatihan' Component={PelatihanLp} />
      <Route path='/pelatihan/detail/:id' Component={PelatihanDetailLp} />

      <Route path='/layanan' Component={LayananLp} />

      <Route path='/dashboard' Component={Dashboard} />
      <Route path='/dashboard/admin' Component={Admin} />
      <Route path='/dashboard/pengguna' Component={Pengguna} />
      <Route path='/dashboard/slideshow' Component={SlideShow} />

      <Route path='/dashboard/galery' Component={Galery} />

      <Route path='/dashboard/layanan' Component={Layanan} />

      <Route path='/dashboard/loker' Component={Loker} />
      <Route path='/dashboard/loker/create' Component={LokerCreate} />
      <Route path='/dashboard/loker/detail/:loker_id' Component={LokerDetail} />
      <Route path='/dashboard/lamaran/kerja' Component={LamaranKerja} />

      <Route path='/dashboard/magang' Component={Magang} />
      <Route path='/dashboard/magang/create' Component={MagangCreate} />
      <Route path='/dashboard/magang/detail/:magang_id' Component={MagangDetail} />
      <Route path='/dashboard/lamaran/magang' Component={LamaranMagang} />

      <Route path='/dashboard/sertifikasi' Component={Certification} />
      <Route path='/dashboard/sertifikasi/create' Component={CertificationCreate} />
      <Route path='/dashboard/sertifikasi/detail/:id' Component={CertificationDetail} />

      <Route path='/dashboard/workshop' Component={Workshop} />
      <Route path='/dashboard/workshop/create' Component={WorkshopCreate} />
      <Route path='/dashboard/workshop/detail/:id' Component={WorkshopDetail} />
      
      <Route path='/dashboard/pelatihan' Component={Training} />
      <Route path='/dashboard/pelatihan/create' Component={TrainingCreate} />
      <Route path='/dashboard/pelatihan/detail/:id' Component={TrainingDetail} />
      
      <Route path='/dashboard/pengaturan' Component={Pengaturan} />
      <Route path='/dashboard/pengaturan/create-lembaga' Component={CreateLembaga} />

    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
