import { Helmet } from "react-helmet"
import LayoutDashboard from './../../../components/layouts/dashboard';
import CustomHeader from './../../../components/customs/header';
import InputDefault from "../../../components/forms/input_default";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getProfile } from "../../../services/profile_service";
import { BaseURL } from "../../../services/base_url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faGear } from "@fortawesome/free-solid-svg-icons";
import { getProfileLembaga } from "../../../services/profile_lembaga_service";
import UpdateProfileLembaga from "./update_lembaga";
import BtnDefault from './../../../components/buttons/default_button';
import UpdateLembagaAddress from "./update_lembaga_address";
import UpdateProfile from "./update_profile";
import UpdatePhotoProfile from "./update_photo_profile";
import CustomSpinner from "../../../components/customs/spinner";
import UpdatePhotoProfileLembaga from "./update_photo_profile_lembaga";
import CustomAlert from "../../../components/customs/alert";
import UpdatePassword from "./update_password";

const Pengaturan = () => {
    const [accessToken, setAccessToken] = useState(null)
    const [registrationCategory, setRegistrationCategory] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [refreshLembaga, setRefreshLembaga] = useState(false)

    const [openUpdateFotoProfile, setOpenUpdateFotoProfile] = useState(false)
    const [openUpdateProfile, setOpenUpdateProfile] = useState(false)
    const [openUpdateFotoProfileLembaga, setOpenUpdateFotoProfileLembaga] = useState(false)
    const [openUpdateProfileLembaga, setOpenUpdateProfileLembaga] = useState(false)
    const [openUpdateAddressLembaga, setOpenUpdateAddressLembaga] = useState(false)
    const [openUpdatePassword, setOpenUpdatePassword] = useState(false)

    const [profile, setProfile] = useState(null)
    const fetchProfile = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getProfile(accessToken)
            if (response.status === 200) {
                setProfile(response.data.data)
            }
        } catch (error) {
            console.log('__')
        }
        setLoading(false)
    }

    const [profileLembaga, setProfileLembaga] = useState(null)
    const fetchProfileLembaga = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getProfileLembaga(accessToken)
            if (response.status === 200) {
                setProfileLembaga(response.data.data)
            }
        } catch (error) {
            console.log('__')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchProfile(accessToken)
            setRefresh(false)
        }

        if (refreshLembaga) {
            fetchProfileLembaga(accessToken)
            setRefreshLembaga(false)
        }
    }, [refresh, refreshLembaga])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-gentrakarya')
        const getRegistrasionCategory = localStorage.getItem('registration_category-gentrakarya')

        if (!getAccessToken) {
            navigate('/login')
        } else {
            setAccessToken(getAccessToken)
            setRegistrationCategory(getRegistrasionCategory)

            fetchProfile(getAccessToken)
            fetchProfileLembaga(getAccessToken)
        }
    }, [])

    const [showAlert, setShowAlert] = useState(false)
    const [message, setMessage] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    return (
        <>
            <Helmet>Pengaturan Akun</Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Pengaturan Akun</p>
                </CustomHeader>

                <div className="w-full flex flex-col md:flex-row items-start p-3 gap-5">
                    <div className="flex flex-col gap-3 w-full md:w-[50%]">
                        <div className="w-[60%] md:w-full mx-auto  aspect-square rounded-2xl border-2 shadow-md uppercase flex items-center justify-center relative overflow-hidden">
                            {
                                loading ? <CustomSpinner /> :
                                    !profile?.image ?
                                        <p className="text-5xl font-bold">{profile?.name?.charAt(0)}</p> :
                                        <img src={BaseURL + "storage/" + profile?.image} alt="Profil Penguna GENTRAKARYA" className="w-full aspect-square object-cover" />

                            }
                            <button onClick={() => setOpenUpdateFotoProfile(true)} className="absolute h-10 aspect-square rounded-xl top-1 right-1 bg-custom-yellow text-black hover:bg-yellow-500 transition-colors">
                                <FontAwesomeIcon icon={faEdit} className="text-xl" />
                            </button>
                        </div>
                        <BtnDefault handleClick={() => setOpenUpdatePassword(true)}>
                            <FontAwesomeIcon icon={faGear} />
                            <p>Ubah Password</p>
                        </BtnDefault>
                    </div>
                    <div className="w-full flex flex-col gap-3">
                        <InputDefault id="name" label="Nama Lengkap" value={profile?.name} disabled={true} />
                        <InputDefault id="email" label="email" value={profile?.email} disabled={true} />
                        <InputDefault id="phoneNumber" label="No telepon" value={profile?.phone_number} disabled={true} />
                        <InputDefault id="gender" label="jenis kelamin" value={profile?.gender?.name} disabled={true} />
                        <InputDefault id="category" label="kategori" value={profile?.registration_category?.name} disabled={true} />
                        {profile?.registration_category?.name === "lembaga" ?
                            <InputDefault id="subCategory" label="sub kategori" value={profile?.registration_sub_category?.name} disabled={true} />
                            : ""
                        }
                        <BtnDefault handleClick={() => setOpenUpdateProfile(true)}>
                            <FontAwesomeIcon icon={faEdit} />
                            <p>Update Profil</p>
                        </BtnDefault>
                    </div>
                </div>

                {['super admin', 'admin', 'lembaga'].includes(registrationCategory) ?
                    <div className="px-3">
                        <div className="px-3 py-2 flex items-center justify-between w-full bg-custom-yellow capitalize rounded-xl">
                            <p className="font-semibold text-2xl ">Profil Lembaga</p>
                            {!profileLembaga &&
                                <BtnDefault handleClick={() => navigate('create-lembaga')}>
                                    <FontAwesomeIcon icon={faGear} />
                                    <p>Atur Lembaga</p>
                                </BtnDefault>
                            }
                        </div>
                    </div>
                    : ""
                }

                {profileLembaga &&
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 p-3 gap-5 ">
                        <div className="w-full flex flex-col gap-3">
                            <div className="w-[410px] aspect-video border-2 shadow-md rounded-xl mx-auto relative overflow-hidden">
                                <img src={BaseURL + "storage/" + profileLembaga?.image} alt="Profil Lembaga - GENTRAKARYA" className="w-full aspect-video object-cover" />
                                <button onClick={() => setOpenUpdateFotoProfileLembaga(true)}
                                    className="absolute h-10 aspect-square rounded-xl top-1 right-1 bg-custom-yellow text-black hover:bg-yellow-500 transition-colors">
                                    <FontAwesomeIcon icon={faEdit} className="text-xl" />
                                </button>
                            </div>
                            <InputDefault id="name" label="Nama Lembaga / Perusahaan" value={profileLembaga?.name} disabled={true} />
                            <InputDefault id="email" label="Kategori" value={profileLembaga?.category?.name} disabled={true} />
                            <InputDefault id="phoneNumber" label="Nama Penanggung Jawab" value={profileLembaga?.responsible_name} disabled={true} />
                            <div className="w-full border-[2px] rounded-xl p-3 ">
                                {profileLembaga?.description}
                            </div>
                            <BtnDefault handleClick={() => setOpenUpdateProfileLembaga(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                                <p>Update Profil Lembaga</p>
                            </BtnDefault>
                        </div>
                        <div className="w-full flex flex-col gap-3">
                            <InputDefault id="name" label="Provinsi" value={profileLembaga?.province?.name} disabled={true} />
                            <InputDefault id="name" label="Kabupaten / Kota" value={profileLembaga?.regency?.name} disabled={true} />
                            <InputDefault id="name" label="Kecamatan" value={profileLembaga?.district?.name} disabled={true} />
                            <InputDefault id="name" label="Desa / Kelurahan" value={profileLembaga?.village?.name} disabled={true} />
                            <InputDefault id="name" label="Link Google Map" value={profileLembaga?.map} disabled={true} />
                            <div className="w-full border-[3px] rounded-xl p-3  ">
                                {profileLembaga?.detail_address}
                            </div>
                            <BtnDefault handleClick={() => setOpenUpdateAddressLembaga(true)}>
                                <FontAwesomeIcon icon={faEdit} />
                                <p>Update Alamat Lembaga</p>
                            </BtnDefault>
                        </div>
                    </div>
                }
            </LayoutDashboard>

            {/* Modal */}
            <UpdatePhotoProfile
                accessToken={accessToken}
                open={openUpdateFotoProfile} setOpen={setOpenUpdateFotoProfile}
                getImage={profile?.image ?? profile?.name.charAt(0)} setRefresh={setRefresh}
            />

            <UpdateProfile
                accessToken={accessToken}
                open={openUpdateProfile} setOpen={setOpenUpdateProfile}
                getName={profile?.name}
                getPhoneNumber={profile?.phone_number}
                getGenderId={profile?.gender?.id}
                setRefresh={setRefresh}
                setAlert={setShowAlert} setAlertMessage={setMessage} setAlertType={setTypeAlert}
            />

            <UpdatePhotoProfileLembaga
                accessToken={accessToken}
                open={openUpdateFotoProfileLembaga} setOpen={setOpenUpdateFotoProfileLembaga}
                getImageLembaga={profileLembaga?.image}
                setRefresh={setRefreshLembaga}
            />

            <UpdateProfileLembaga
                accessToken={accessToken} setRefresh={setRefreshLembaga}
                open={openUpdateProfileLembaga} setOpen={setOpenUpdateProfileLembaga}
                getName={profileLembaga?.name}
                getPhoneNumber={profileLembaga?.phone_number}
                getEmail={profileLembaga?.email}
                getResponsibleName={profileLembaga?.responsible_name}
                getDescription={profileLembaga?.description}
                setAlert={setShowAlert} setAlertMessage={setMessage} setAlertType={setTypeAlert}
            />

            <UpdateLembagaAddress
                accessToken={accessToken} setRefresh={setRefreshLembaga}
                open={openUpdateAddressLembaga} setOpen={setOpenUpdateAddressLembaga}
                getProvinceId={profileLembaga?.province?.id}
                getRegencyId={profileLembaga?.regency?.id}
                getDistrictId={profileLembaga?.district?.id}
                getVillageId={profileLembaga?.village?.id}
                getMap={profileLembaga?.map}
                getDetail={profileLembaga?.detail_address}
                setAlert={setShowAlert} setAlertMessage={setMessage} setAlertType={setTypeAlert}
            />
            
            <UpdatePassword
                accessToken={accessToken}
                open={openUpdatePassword} setOpen={setOpenUpdatePassword}
                setShowAlert={setShowAlert} setMessage={setMessage} setTypeAlert={setTypeAlert} />

            <CustomAlert showAlert={showAlert} setShowAlert={setShowAlert} message={message} type={typeAlert} />
        </>
    )
}

export default Pengaturan