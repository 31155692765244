import { faCaretRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useHref } from "react-router-dom"

const CustomAccording = ({ children, name, heigth, activeLink }) => {
    const currentPath = useHref()
    const [open, setOpen] = useState(false)
    return (
        <div className="w-full rounded-l-md ">
            <button type="button" onClick={() => setOpen(!open)} className="w-full flex items-center justify-between pr-3 relative group ">
                <div className="w-full flex items-center space-x-3 font-semibold py-2 px-3 rounded-l-full relative group">
                    <p className="z-10 w-full text-start">{name}</p>
                </div>
                <FontAwesomeIcon icon={faCaretRight} className={`z-10 transition-all duration-500 ${open === true || activeLink ? 'rotate-90' : 'rotate-0'}`}></FontAwesomeIcon>
                <div className={`absolute h-full bg-white right-0 rounded-md md:rounded-l-full group-hover:w-full transition-all duration-500  ${open === true || activeLink ? 'w-full' : 'w-0'} `}></div>
            </button>
            <div className={`pl-3 h-0 overflow-hidden transition-all duration-500 bg-black/30 rounded-l-xl flex flex-col gap-1 ${open === true || activeLink ? `${heigth} pt-1` : 'h-0'}`}>
                {children}
            </div>
        </div>
    )
}

export default CustomAccording