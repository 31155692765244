import apiService from "./api_service";

export const getTrainingImage = async (id) => {
    try {
        const response = await apiService.get(`training-image?training_id=${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const postTrainingImage = async (accessToken, data) => {
    try {
        const response = await apiService.post(`training-image/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const deleteTrainingImage = async (accessToken, ids) => {
    try {
        const response = await apiService.post(`training-image/delete`, ids, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};