import { useState } from 'react';
import InputDefault from '../../../components/forms/input_default';
import LayoutModal from '../../../components/layouts/modal';
import BtnDefault from '../../../components/buttons/default_button';
import CustomSpinner from '../../../components/customs/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { updateProfileImage } from '../../../services/profile_service';
import { BaseURL } from '../../../services/base_url';
import { updateProfileLembagaImage } from '../../../services/profile_lembaga_service';

const UpdatePhotoProfileLembaga = ({ accessToken, open, setOpen, getImageLembaga, setRefresh }) => {
    const [loading, setLoading] = useState(false)

    const [imageLembaga, setImageLembaga] = useState(null)
    const [imagePreviewLembaga, setImagePreviewLembaga] = useState(null)

    const [errorImageLembaga, setErrorImageLembaga] = useState(null)

    const handleSubmitImageLembaga = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorImageLembaga(null)
            const data = new FormData()
            data.append('image', imageLembaga)
            const response = await updateProfileLembagaImage(accessToken, data)
            if (response.status === 200) {
                setRefresh(true)
                setOpen(false)
                setImageLembaga(null)
                setImagePreviewLembaga(null)
            } else {
                alert('Gagal update foto profil..!')
            }
        } catch (error) {
            alert('Silahkan cek oneksi internet Anda..!')
        }
        setLoading(false)
    }

    return (
        <LayoutModal name="Update Foto Profil Lembaga" showModal={open} width="w-[90%] md:w-[500px]" height="h-[450px]">
            <form onSubmit={handleSubmitImageLembaga} className='px-3 flex flex-col gap-3'>
                <div className='flex flex-col items-center justify-center gap-3'>
                    <label htmlFor="imageLembaga" className='border-2 border-dotted border-black rounded-xl px-3 py-2 flex items-center gap-3 cursor-pointer'>
                        <FontAwesomeIcon icon={faUpload} />
                        <p>Upload Image</p>
                    </label>
                    <input
                        id='imageLembaga'
                        type="file"
                        accept="image/*"
                        className='hidden'
                        onChange={(e) => {
                            const file = e.target.files[0];
                            setImageLembaga(file)
                            if (file) {
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    setImagePreviewLembaga(reader.result);
                                };
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                    {
                        imagePreviewLembaga ? <img src={imagePreviewLembaga} alt="Preview" className="w-[400px] aspect-video object-cover rounded-xl border shadow-md" /> :
                            getImageLembaga && <img src={BaseURL + "storage/" + getImageLembaga} alt="Foto Profil - GENTRAKARYA" className="w-[400px] aspect-video object-cover rounded-xl border shadow-md" />
                    }
                </div>
                <div className="flex items-end justify-center gap-3">
                    <BtnDefault handleClick={() => setOpen(false)} addClass="bg-red-500 hover:bg-red-600 w-[150px]">
                        Kembali
                    </BtnDefault>
                    <BtnDefault type={"submit"} addClass="w-[150px]">
                        {loading ?
                            <CustomSpinner color={"bg-white"} /> :
                            <p>Simpan</p>
                        }
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UpdatePhotoProfileLembaga