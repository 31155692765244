import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import HandleClickOutside from '../utils/handle_click_outside';



const SelectDefault = ({
    options, 
    getSelected, 
    onSelect, name, disabled, value, placeholder = 'Pilih salah satu', id, width, addClass, capitalize, addOption, errorMessage,
    position
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (getSelected) {
            const selected = options.find(option => option.value === getSelected);
            setSelectedOption(selected || null); // Setel opsi yang dipilih berdasarkan getSelected
            if (selected) {
                onSelect(selected.value); // Panggil onSelect jika ada opsi yang dipilih
            }
        }
    }, [getSelected, options, selectedOption]); // Dependensi untuk memantau perubahan getSelected dan options

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSelect(option.value);
        setIsOpen(false);
    };

    const filterRef = useRef(null)

    return (
        <>
            <div className="custom-select-container text-black text-xs md:text-sm lg:text-base" ref={filterRef} style={{ position: 'relative', }}>
                <label htmlFor={id || ""} className='font-semibold'>{name}</label>
                <div id={id || ""}
                    className={`selected-option w-full rounded-md px-3 py-2 border-2 focus:border-custom-navy outline-none flex items-center gap-3 hover:border-blue-500 justify-between bg-white capitalize text-black ${disabled && disabled === true ? "" : "border-[2px] hover:border-custom-navy cursor-pointer"} ${isOpen ? "border-blue-500" : ""}`}
                    onClick={() => {
                        if (!disabled) {
                            setIsOpen(!isOpen);
                        }
                    }}

                >
                    {value ? selectedOption ? selectedOption.label : placeholder : placeholder}
                    <FontAwesomeIcon icon={faCaretDown} className={`transition-all ${isOpen ? '-rotate-90' : 'rotate-0'}`} />
                </div>
                {errorMessage && <p className='text-red-500 text-sm'>{errorMessage}</p>}
                {isOpen && (
                    <div
                        className={`options-dropdown absolute top-[100%] border-2 rounded-xl max-h-[300px] mt-2 bg-white z-50 p-3 overflow-auto scrollbar-thin scrollbar-thumb-custom-navy scrollbar-track-custom-dark-yellow scrollbar-track-rounded-full scrollbar-thumb-rounded-full ${width} flex flex-col gap-3 ${position ? position : "left-0"  }`}
                    >
                        {addOption}
                        {options.map((option) => (
                            <div
                                key={option.value}
                                onClick={() => handleSelect(option)}
                                className={`option-item hover:bg-gray-200 rounded-full px-3 w-full ${capitalize ? capitalize : "capitalize"} ${addClass}`}
                                style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                            >
                                {option.label}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <HandleClickOutside setOpen={setIsOpen} filterRef={filterRef} />
        </>
    );
};

export default SelectDefault;