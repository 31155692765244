import apiService from "./api_service";

export const getCertificationImage = async (id) => {
    try {
        const response = await apiService.get(`certification-image`, {
            params: {
                certification_id: id
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const postCertificationImage = async (accessToken, data) => {
    try {
        const response = await apiService.post(`certification-image/create`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};

export const deleteCertificationImage = async (accessToken, ids) => {
    try {
        const response = await apiService.post(`certification-image/delete`, ids, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
};