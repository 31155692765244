import { useHref } from "react-router-dom"
import SideLink from "../../../links/sidelink"

const SidebarMasyarakat = () => {
    const currentPath = useHref()
    return (
        <>
            <SideLink name="Dashboard" link="/dashboard" />
            <SideLink name="Data Lamaran" link="/dashboard/lamaran/kerja" />
            <SideLink name="Pengaturan" link="/dashboard/pengaturan" />
        </>
    )
}

export default SidebarMasyarakat